import React, { useEffect } from 'react'
import CustomisableUserProfilesController from './CustomisableUserProfilesController'
import {
  CardContent, Card,
  Popover, Button,
  Divider, Modal,
  Select, Grid,
  createTheme, Radio,
  Dialog, DialogContent,
  DialogTitle, DialogActions,
  IconButton, CircularProgress
} from "@material-ui/core"
import OptingoutComponent from '../../documentopener/src/JobsSection/OptingoutComponent.web'
import CustomViewJobRow from '../../documentopener/src/JobsSection/CustomViewJobRow.web'
import {defaultMaterialTheme} from '../../documentopener/src/JobsSection/ViewJobsPage.web'
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import CloseIcon from '@material-ui/icons/Close';
import { WarningIcon, Experience,SuccessIcon, DropDownIcon, JobType, Salary, CreatedOn, Company, InfoIcon, ScheduleIcon, CalenderIcon, RadioCheckedIcon, RadioOutlinedIcon, SelectedIcon, RejectedIcon, CandidateRejected, CandidateSelected } from "./assets"
import { Formik } from 'formik'
import moment from "moment"
import MeetingPage from '../../videos/src/MeetingPage.web';

import { ThemeProvider } from "@material-ui/styles"
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RateInterviewer from './RateInterviewer';

const mainConfigJson = require("../../../framework/src/config");
export const getSlotName = (status: any) => {
  return status === "missed_out" ? "Missed Slot" : status + " Slot";
}
export const getSlotClassName = (status: any) => {
  let className = "candidate-details-normal";
  if (status === "cancelled" || status === "missed_out" || status === "confirmed") {
    className = status === "confirmed" ? "candidate-details-confirmed" : "candidate-details-missed"
  }
  return className;
}
export const getInterviewStartTime = (time: any) => {
  return time ? moment(time)?.format("DD MMMM YYYY hh:mm A") : "NA"
}
export const getJobRole = (role: any) => {
  return role ? role : "";
}
export const getCompanyName
  = (name: any) => {
    return name ? name : "";
  }
export const getJobLocation = (name: any) => {
  return name ? ", " + name : "";
}
export const getSalaryRange = (min: any, max: any) => {
  return `INR ${(min + "-" + max + " LPA") || ""}`;
}
export const getJobCreatedDate = (date: any) => {
  return date ? moment(date).format('DD MMM YYYY') : "";
}

export const checkIsActiveRound = (round: any, ind: any, slotsDetails: any) => {
  return (round?.round_status || (ind == 0 && slotsDetails?.job_slots.length > 0))
}

export const getSkillData = (skillData: any, isPrimary: boolean) => {
  return skillData?.filter((skill: any) => isPrimary ? !skill?.optional : skill.optional) || [{ name: "" }]
}

export default class ViewJobDetails extends CustomisableUserProfilesController {
  jobId: any = 0
  iStartTime: any = "";
  iEndTime: any = "";
  updatePage: any;
  updateInterviewStatus: any;
  slotId: any
  constructor(props: any) {
    super(props)
    this.getStatus = this.getStatus.bind(this);
    this.ShowSlots = this.ShowSlots.bind(this);
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
    this.TimelineUnit = this.TimelineUnit.bind(this);
    this.JoinSlots = this.JoinSlots.bind(this);
  }


  Heads_Tags({ head, data }: { head: string, data: any[] }) {
    return (<>
      <span className="view-job-detail-headings">{head || ""}</span>
      {data && data.length && data.map((tag, ind) => tag?.name ? <div key={tag.name + ind} className="job-card-label" >{tag.name}</div> : "") || ""}
    </>
    )
  }

  InfoItem({ head, image, value }: { head: string, image: any, value: string }) {
    return (<div className="view-job-info-items">
      <img height="40" src={image} alt="dsf" />
      <div className="view-job-info-item" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: "column" }} >
        <span className="view-job-info-head" > {head} </span>
        <span className="view-job-info-value" > {value} </span>
      </div>
    </div>)
  }

  handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.setState({ element: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ element: null });
  };
  getStatus(status: string) {
    return <div
      className={`${status == "opted_out"
        ? "status-warning"
        : status == "selected"
          ? "status-completed"
          : status == "rejected"
            ? "status-error"
            : status == "job_closed"
              ? "status-opted-out"
              : status == "cancel"
                ? "status-error"
                : status == "applied"
                  ? "status-info"
                  : "status-log"
        } dashboard-table-status`}
    >
      {status === "opted_out" ? "opted out" : status === "job_closed" ? "Job Closed" : status}
    </div>
  }
  JoinSlots({ slot, start }: any) {
    return <div className="flex-column" >
      <div className="flex" style={{ fontSize: "16px", marginBottom: 14 }} >
        <span className="f-1" >
          Confirmed Slot
          <div className="fw-bold" style={{ fontSize: '18px', margin: "6px 0" }}>
            {slot && slot[start] ? moment(slot[start])?.format("DD MMMM YYYY hh:mm A") : "NA"}
          </div>
        </span>
        <span className="f-1" >
          Interviewer
          <div className="fw-bold" style={{ fontSize: '18px', margin: "6px 0" }}>
            {this.state.slotsDetails.panel && this.state.slotsDetails.panel.first_name + " " + this.state.slotsDetails.panel.last_name}
          </div>
        </span>
      </div>

      <div className="hy-btn"
          data-test-id="join"
          style={{
          borderRadius: "9px",
          textAlign: "center",
          padding: "10px 0",
          fontWeight: 600
        }}
        onClick={() => {
          this.joinInterview(slot?.id);
        }}
      >
        Join Interview
      </div>
    </div>
  }
  ShowSlots(props: any) {
    let hideConfirmSlot: boolean = (this.state.jobDetails.status === "closed" || this.state.slotsDetails.status === "opted_out");
    console.log("props.round?.all_slots", props.round?.all_slots)
    const start = "interview_start_time";
    const end = "interview_end_time";
    const slots: any[] = this.state?.slotsDetails?.job_slots.filter((item: any) => item.round_id === props.round?.id);
    const confirmedSlot = slots.filter((slot: any) => (slot.status === "confirmed" || slot.status === "joined"))
    const confirmed = confirmedSlot.length > 0
    const currentDate = moment()
    const isJoined = slots.filter((slot: any) => slot.status === "joined");
    if (confirmed && confirmedSlot[0][start] && confirmedSlot[0][end]) {
      this.iStartTime = confirmedSlot[0][start];
      this.iEndTime = confirmedSlot[0][end];
    }
    if (isJoined.length > 0 && (currentDate.diff(new Date(isJoined[0].interview_end_time), "minutes") > 0)) {
      return (<InterviewCompleted />);
    }
    if (confirmed && (currentDate.diff(new Date(confirmedSlot[0][start]), "minutes") > -5) && (currentDate.diff(new Date(confirmedSlot[0][end]), "minutes") <= 0)) {
      return (<this.JoinSlots slot={confirmedSlot[0]} start={start} />);
    }
    return <>
      Interview Slots
      <div style={{ position: "relative", display: "inline" }} >
        <img height="13" onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose} style={{ position: "relative", top: "2px", left: "10px" }} src={InfoIcon} alt="info-icon" />
        <Popover
          style={{ pointerEvents: "none" }}
          open={!!this.state?.element}
          anchorEl={this.state?.element}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <div style={{ margin: "10px", maxWidth: "400px" }}>We have assigned {slots.length} slots for your interview. kindly confirm your slot before 30 mins. Also make sure you are available on your confirmed slot if you failed to attend interview then you can give interview in remaining slots.</div>
        </Popover>
      </div>
      <div className={`interview-slots responsive-flex-dc ${hideConfirmSlot ? "disabled" : ""}`}>
        {slots?.map((slot: any, ind: any) =>
          <div key={"confirmed-slot " + ind} className={getSlotClassName(slot?.status)}>
            {getInterviewStartTime(slot[start])}
            {slot[start] && (new Date() < new Date(slot[start])) && (this.state.comfirmSlot && slot?.status === "confirmed")
              &&
              (<span
                style={{ pointerEvents: "none", marginLeft: "10px" }}
              >
                Confirmed Slot
              </span>)
            }
            {slot[start] && (new Date() < new Date(slot[start])) && (this.state.comfirmSlot && (slot?.status !== "confirmed" && slot?.status != "missed_out" && slot?.status !== "cancelled" && slot?.status !== "joined"))
              &&
              (<span
                data-test-id="slot"
                style={{ pointerEvents: "auto", marginLeft: "10px" }}
                onClick={() => { this.selected = ind; this.confirmSlot(slot.id) }}
              >
                Confirm Slot
              </span>)
            }
            {slot?.status !== "not_confirmed" && slot?.status !== "joined" &&
              (<span style={{ pointerEvents: "none", marginLeft: "10px", textTransform: "uppercase" }}>
                {getSlotName(slot?.status)}
              </span>)
            }
          </div>
        )}
      </div>
      {(!confirmed && !hideConfirmSlot) &&
        <div style={{ display: "flex", justifyContent: "space-between" }} className="slot-action-div">
          <Button style={{ flex: 1, padding: "5px 15px", border: "1px solid", fontWeight: 600, fontSize: "14px", borderRadius: 8 }} onClick={props.changeSlots} variant="outlined">CHANGE SLOT</Button>
          {!this.state.comfirmSlot && <Button style={{ flex: 1, marginLeft: "10px", padding: "5px 15px", border: "none", fontWeight: 600, fontSize: "14px", color: "#fff", background: "grey", borderRadius: 8 }} onClick={() => this.setState({ ...this.state, comfirmSlot: true })} >CONFIRM SLOT</Button>}
        </div>
      }
    </>
  }
 
  TimelineUnit(props: any) {
   return <>
      <span className={`candidate-details-round-heading ${props.active ? "" : "disbled-round-heading"}`} >
        <TimelineStep active={props.active} /> Round {props.ind + 1} : {props.name} </span>
      <div className="timeline-content-wrapper ">
        {props.lastSlot ? null : <div className="dashed-image" ></div>}
        {console.log("prpos", this.state?.slotsDetails?.job_slots, this.state?.slotsDetails?.job_slots?.length)}
        {
          (props.round.round_status === "round_in_progress" || props.round.round_status === "round_begin") && props.round.interview_status === "in_progress" && props.round?.all_slots?.length > 0 &&
          <div  className="timeline-content">
            <this.ShowSlots data-test-id="Dialog" changeSlots={() => this.handleDialog("", "changeInterviewSlot")} round={props.round} />
          </div>
        }

        {props.ind > 0 && (props.round.round_status == "round_begin" || props.round.round_status == "round_in_progress") && (!props.round.all_slots?.length) &&
          <div className="timeline-content">
            <ScheduleNow />
          </div>
        }

        {props.ind == 0 && (!this.state?.slotsDetails?.job_slots?.length) &&
          <div className="timeline-content">
            <ScheduleNow />
          </div>
        }

        {props.ind == 0 && (this.state?.slotsDetails?.job_slots?.length > 0 && !props.round?.round_status) &&
          <div className="timeline-content">
            
            <this.ShowSlots data-test-id="handleDialog_test" changeSlots={() => this.handleDialog("", "changeInterviewSlot")} round={props.round} />
          </div>
        }

        {((props.round.round_status === "round_review_pending")) &&
          <div className="timeline-content">
            <InterviewCompleted />
          </div>
        }
        {(props.round.round_status === "round_completed" || props.round.round_status === "round_selected") &&
          <div className="timeline-content">
            <InterviewCleared round={props.ind + 1} />
          </div>
        }

        {props.round.round_status === "round_rejected" &&
          <div className="timeline-content">
            <InterviewRejected round={props.ind + 1} />
          </div>
        }

        {
          //@ts-ignore
          this.state.openMeeting && <MeetingPage data-test-id="page" reviewPopup={() => { this.setState({ ...this.state, openMeeting: false, showDialog: { show: "", msg: "rateInterviewer" } }); this.props.havingIssue(false) }} />
        }

      </div>
    </>
  }

  async componentDidMount() {
    super.componentDidMount()
    // @ts-ignore
    this.jobId = this.props?.location?.state?.JobId
    if (!this.jobId) {
      //@ts-ignore
      this.props.history.push("/Candidate/Home")
    }
    this.getJobDetails(this.jobId)
    this.getCandidateInterviewSlots(this.jobId)
    this.getExperienceList();
    this.getLocationList();

    this.updatePage = setInterval(() => {
      const currentDate = moment()
      if (((currentDate.diff(new Date(this.iStartTime), "minutes") > -5) && (currentDate.diff(new Date(this.iEndTime), "minutes") < 0)) || (currentDate.diff(new Date(this.iEndTime), "minutes") > 0)) {
        clearInterval(this.updatePage)
        this.forceUpdate()
      }
      this.getCandidateInterviewSlots(this.jobId);
    }, 20000);
    this.updateInterviewStatus = setInterval(() => {
      this.getCandidateInterviewSlots(this.jobId);
    }, 30000);
    window.onpopstate = () => {
      if (this.state.openMeeting) {
        // @ts-ignore
        this.props.history.go(1);
      }
    }
  }

  async componentWillUnmount() {
    clearInterval(this.updatePage)
    clearInterval(this.updateInterviewStatus)
  }

  handleDialog = (show: string = "", msg: string = "") => {
    this.setState({ ...this.state, showDialog: { show: show, msg: msg } })
  }
  Selected = () => {
    return (this.state.slotsDetails.status === "selected" &&
      <div className='notification-bar selected' >
        <img height={40} src={CandidateSelected} alt="<#" />
        Congratulations ! you have selected for the job we will contact you for further onboarding process
      </div>)
  }
  Rejected = () => {
    return (this.state.slotsDetails.status === "rejected" &&
      <div className='notification-bar rejected' >
        <img height={40} src={CandidateRejected} alt=":(" />
        You have not cleared the interview. We would not be able to proceed with your candidature.
      </div>)
  }
  getExperience = (id: any) => {
    let name = "";
    const obj = this.state.experienceList.find((item: any) => Number(id) === item.id);
    if (obj) {
      name = obj.years + `${obj.years === "1" ? " Year" : " Years"}`;
    } else if (id && !Number(id)) {
      name = id;
    }
    return name;
  }
  getSelectedLocation = (ids: any) => {
    let locationArray: any[] = [];
    if (ids && ids.length > 0 && typeof (ids) !== "string") {
      ids.filter((id: any) => {
        const location = this.state.locationList.find((i: any) => i.id == id)?.name || "";
        if (location) {
          locationArray.push({ name: location });
        }
      })
    }
    return locationArray;
  }
  showJobCompanyLogo = () => {
    const logo: string = this.state.jobDetails?.get_dummy_data?.company_logo;
    if (logo) {
      return <img style={{ height: "55px", borderRadius: "7px", marginRight: "8px", width: "70px" }} src={mainConfigJson.baseURL + logo} />
    } else {
      return <img className='comp_icon' src={Company} alt="arrow" style={{ height: "55px", borderRadius: "7px", marginRight: "8px", width: "70px" }} />
    }
  }
  showLoader = () => {
    return <>
      {this.state.loader &&
        <div className={"circular-loader "}>
          <CircularProgress style={{ width: "30px", height: "30px", color: '#083247' }} />
        </div>}
    </>
  }
  showJobClosedSection = () => {
    return <>
      {this.state.jobDetails.status === "closed" &&
        <div>
          <h3 style={{ textAlign: 'center' }}>Job Closed</h3>
        </div>}
    </>
  }
  handleOptOut = () => {
    this.handleDialog("", "optOut");
  };
  showOptedOutComponent = () => {
    return <>
      {(this.state.slotsDetails.status !== "opted_out" && this.state.jobDetails.status !== 'closed')
        &&
        <>
          <div className="optout-btn pointer" onClick={this.handleOptOut} >
            OPTING OUT?
          </div>
          <OptOut show={this.state.showDialog.msg === "optOut"} optout={this.optoutCandidate} handleClose={this.handleDialog} candidateId={this.id} jobId={this.jobId} />
        </>}
    </>
  }
  showChangeSlots = () => {
    return <>
      {this.state.showDialog.msg === "changeInterviewSlot" &&
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
          <ChangeSlots show={this.state.showDialog.msg === "changeInterviewSlot"} msg={this.state.showDialog.msg}
            changeSlots={(vals: any) => {
              vals.forEach(async (val: any) => val.id && val.time && val.date && this.changeSlots(val))
            }}
            handleClose={this.handleDialog}
            slots={this.state.slotsDetails?.job_slots?.filter((item: any) => item.round_id === this.currentRoundId)}
          />
        </MuiPickersUtilsProvider>
      }
    </>
  }
 
  showJobDescription = () => {
    return <p className="view-job-description" >
      {this.state.showMore || this.state.jobDetails?.description?.length <= 550 ? this.state.jobDetails?.description : `${this.state.jobDetails?.description?.substring(0, 550)}...`}
      {this.state.jobDetails?.description?.length > 550 &&
        <span className="view-job-description-show-hide pointer" onClick={() => this.setState({ ...this.state, showMore: !this.state.showMore })}>{this.state.showMore ? "Show Less" : "Show More"}</span>}
    </p>
  }
  handleCloseReviewModel = () => {
    this.setState({
      open_review_model: false,
      InterviewRateMsg: "",
    });
  };
  showLocationChip = (location: any) => {
    let locationData: any;
    if (location) {
      locationData = typeof (location) === "string" ? [{ name: location }] : this.getSelectedLocation(location)
    }
    else {
      locationData = [{ name: "" }]
    }
    return locationData;
  }
  render() {
    const { jobDetails, slotsDetails } = this.state;
    const goBack = () => {
      // @ts-ignore
      const companyId = this.props?.location?.state?.companyId;
      //@ts-ignore
      this.props.history.replace({ pathname: "/Candidate/Home", state: { companyId: companyId } });
    }
    return (<>
      <IconButton onClick={goBack} size='small'><KeyboardBackspaceRoundedIcon fontSize='large' /></IconButton>
      {jobDetails === "" ? <h3>Loading...</h3> :
        <>
          {this.Selected()}
          {this.Rejected()}
          <div className="candidate-view-job-details-wrapper responsive-flex-dc">
            <Card className="view-jobs-job-description-wrapper"  >
              <CardContent >
                <div style={{ display: 'flex', marginBottom: '25px' }}>
                  {this.showJobCompanyLogo()}
                  <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-evenly" }}>
                    <span style={{ fontSize: "19px", fontWeight: 600, textTransform: "capitalize" }} > {getJobRole(jobDetails?.job_role)} </span>
                    <span style={{ fontSize: "15px" }} > {getCompanyName(jobDetails?.get_dummy_data?.company_name)}{getJobLocation(jobDetails?.get_dummy_data?.client_admin_location)} </span>
                  </div>
                </div>

                <div className="view-job-info-list">
                  <this.InfoItem head={"Job Type"} image={JobType} value={getJobRole(jobDetails?.get_dummy_data?.job_type)} />
                  <this.InfoItem head={"Salary Range"} image={Salary} value={getSalaryRange(Number(jobDetails?.min_salary), Number(jobDetails?.max_salary))} />
                  <this.InfoItem head={"Experience"} image={Experience} value={this.getExperience(jobDetails?.experience)} />
                  <this.InfoItem head={"Created On"} image={CreatedOn} value={getJobCreatedDate(jobDetails?.created_at)} />
                </div>
                <this.Heads_Tags head="Job Location" data={this.showLocationChip(jobDetails?.location)} />
                <span className="view-job-detail-headings">Job Description</span>
                {this.showJobDescription()}

                <this.Heads_Tags head="Mandatory Skills" data={getSkillData(jobDetails?.get_dummy_data?.skills, true)} />
                <this.Heads_Tags head="Optional Skills" data={getSkillData(jobDetails?.get_dummy_data?.skills, false)} />
              </CardContent>
            </Card>
            <RateInterviewer show={this.state.showDialog.msg === "rateInterviewer"} handleClose={this.handleDialog}   ReviewMsg={this.state.InterviewRateMsg}  rateInterviewer={this.rateInterviewer} panel_candidate_slot_id={slotsDetails?.panel_candidate_slot_id} />


            <Modal
        open={this.state.open_review_model}
        aria-labelledby="interview-confirm-slot-modal-title"
        aria-describedby="interview-confirm-slot-modal-description"
        className="jobs-success-model interviews-page">
          <div className="infromation-block interviews-page">
            {this.state.InterviewRateMsg == 'Your review has been saved successfully' ?
              <>
                <img src={SuccessIcon} width='120px' alt="relative image" style={{ textAlign: 'center' }} />
                <h4>Review Saved</h4>
              </>
              : <>
                <CloseIcon style={{ textAlign: 'center', color: 'red' }} />
                <h4>Error Ocurred</h4>
              </>
            }
            <h6 style={{marginBottom: "-10px"}}>
              {this.state.InterviewRateMsg}
            </h6>
          <Button data-test-id="open" onClick={this.handleCloseReviewModel}
          className="btn btn-submit hy-btn"
          style={{ fontWeight: 600}}
          fullWidth
        >
          OKAY
        </Button>
        </div>
      </Modal>
            <div style={{ minWidth: "25px" }} />
            <div style={{ position: "relative" }}>
              <Card className="view-jobs-job-description-wrapper" style={{ maxWidth: "550px", minWidth: "450px", position: "relative" }}  >
                {this.showLoader()}
                <CardContent >
                  <div className="header-item responsive-flex-dc" >
                    <span className='fw-bold' style={{ fontSize: "24px", whiteSpace: "nowrap" }} >Interview Process</span>
                    {this.getStatus(slotsDetails.status)}
                  </div>
                  <Divider style={{ margin: "20px 0px" }} />

                  <SuccessDialogBox
                    show={this.state.showDialog.show === "true"}
                    msg={this.state.showDialog.msg}
                    handleClose={this.handleDialog}
                  />
                  {this.showChangeSlots()}
                  {(slotsDetails.status !== "closed") &&
                    <>
                      {slotsDetails?.interview_rounds?.map((round: any, ind: number) => <>
                        <this.TimelineUnit key={"slot-round " + ind} active={checkIsActiveRound(round, ind, slotsDetails)} ind={ind} name={round.name} lastSlot={ind + 1 == slotsDetails.interview_rounds.length} round={round} />
                      </>
                      )
                      }
                    </>
                  }
                  {this.showJobClosedSection()}
                </CardContent>
              </Card>
              {this.showOptedOutComponent()}
              {
                //@ts-ignore
                < HavingIssue data-test-id="issuePopUp" show={this.props.showHavingIssuePopup}
                  handleClose={() => {
                    
                    // this.setState({havingAnIssue:false})

                    //@ts-ignore
                    this.props.havingIssuePopup(false)
                  }}
                  handleSubmit={(values: any) => {
                    const jobSlotId = slotsDetails?.job_slots?.find((item: any) => ((item.round_id === this.currentRoundId) && (item.status === "confirmed")))?.id;
                    const value = {
                      ...values,
                      candidate_id: slotsDetails?.account_id,
                      job_slot_id: jobSlotId,
                      panel_id: slotsDetails?.panel?.id
                    }
                    this.haveIssueSubmit(value);
                  }}
                  candidateId={0}
                  jobId={0} />
              }
            </div>
          </div>
        </>
      }
    </>
    )
  }
}
export function OptOut(params: any) {
  const [err_msg, setErrorMsg] = React.useState<any>("")
  return (<>
    <Dialog open={params.show} fullWidth maxWidth="sm" scroll='body' className="default-timing-dialog-wrapper">
      <DialogTitle className="dialog-title">
        <div className='dialog-header' style={{ justifyContent: "space-between", display: "flex" }}>
          <div style={{ fontSize: 22 }}>Opting Out?</div>
          <IconButton size="small" onClick={params.handleClose}><CloseIcon className="close-icon-btn" /></IconButton>
        </div>
      </DialogTitle>
      <OptingoutComponent
        setErrorMsg={setErrorMsg}
        optout={params.optout}
        jobId={params.jobId}
        handleClose={params.handleClose}
        candidateId={params.candidateId} err_msg={err_msg} />

    </Dialog>
  </>)
}
export function ScheduleNow(props: any) {
  return <>
    <div className="schedule-interview-content">
      <img src={WarningIcon} height={40} width={40} alt="warning" />
      <div>Interview is not scheduled yet.</div>
    </div>
  </>
}
export function SuccessDialogBox(params: any) {
  return <Modal
    open={params.show}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className="jobs-success-model"
  >
    <div className="infromation-block">
      {params.msg === "" ? <><h1 > Some error occured </h1> <button className="hy-btn" onClick={params.handleClose}>Close</button> </> : null}
      {params.msg === "scheduleInterview" ?
        <>
          <img src={ScheduleIcon} alt="relative image" style={{ height: "65px", margin: "35px 0" }} />
          <h4>
            Schedule Interview
          </h4>
          <h6 >Are you sure want to schedule the interview ? <br />
            Slots will be auto generated</h6>
          <div style={{ display: "flex", height: "56px", marginTop: "24px" }}>
            <Button onClick={()=> {
              params.handleClose()
            }}
              variant='outlined'
              style={{ fontWeight: 600, marginRight: "5px", flex: 1, borderRadius: "8px" }}
            >
              CANCEL
            </Button>
            <Button onClick={() => {
              params.handleClose()
            }}
              className="hy-btn"
              style={{ fontWeight: 600, marginLeft: "5px", flex: 1, borderRadius: "8px" }}
            >
              CONFIRM
            </Button>
          </div>
        </>
        :
        null}
        <CustomViewJobRow params={params} />
    </div>
  </Modal>
}
export const isDisabledSlot = (slotStatus: string) => {
  return (slotStatus === "cancelled" || slotStatus === "missed_out");
}
export const setSlotInputClassName = (slotStatus: string) => {
  return (slotStatus === "cancelled" || slotStatus === "missed_out") ? "select_date disabled" : "select_date";
}
export const setSlotErrorMessage = (data: any, index: any, errors: any, hasValidObject: any) => {
  let isValidObject = hasValidObject;
  if (!data[index].time) {
    errors[index] = "Time is Required";
  } else if (!data[index].date) {
    errors[index] = "Date is Required";
  } else {
    errors[index] = "";
    isValidObject = true;
  }
  return { errors, isValidObject };
}
export const checkSlotValidation = (data: any) => {
  let errors = data.map((i: any) => "");
  let hasValidObject = false;
  const ids: any[] = data.map((item: any, ind: number) => (item.time || item.date) &&
    (item.status !== "cancelled" && item.status !== "missed_out") && ind);
  const indexes = ids.filter((i: any) => i !== "");
  if (indexes.length > 0) {
    indexes.forEach((ind: any) => {
      if (typeof (ind) === "number") {
        const eResponse = setSlotErrorMessage(data, ind, errors, hasValidObject);
        errors = eResponse.errors;
        hasValidObject = eResponse.isValidObject;
      }
    })
  } else {
    const emptyObjectIndex = data.findIndex((item: any) => (!item.time && !item.date) &&
      (item.status !== "cancelled" && item.status !== "missed_out") && !hasValidObject);
    if (emptyObjectIndex > -1) {
      const eResponseForEmptyOb = setSlotErrorMessage(data, emptyObjectIndex, errors, hasValidObject);
      errors = eResponseForEmptyOb.errors;
      hasValidObject = eResponseForEmptyOb.isValidObject;
    }
  }
  return errors;
}
export function ChangeSlots(props: any) {
  const [data, setData] = React.useState(props?.slots?.map((slotOb: any) => ({ id: slotOb.id, time: "", date: "", status: slotOb.status})));
  const [errorOb, setErrorOb] = React.useState<any[]>([]);

  const confirmSlots = () => {
    const errorList: any = checkSlotValidation(data);

    setErrorOb(errorList);
    const isValidData = errorList.find((item: any) => item !== "") ? false : true;
    if (isValidData) {
      props.handleClose();
      props.changeSlots(data)
    }
  }
  const getFormattedDate = (date: string) => {
    let dateString = "";
    if (date) {
      const day = date.substring(0, date.indexOf("-"));
      const month = date.substring(
        date.indexOf("-") + 1,
        date.lastIndexOf("-")
      );
      const year = date.substring(
        date.lastIndexOf("-") + 1,
        date.length
      );
      dateString = `${day}-${Number(month) + 1}-${year}`;
    }
    return dateString;
  }
  const getFormattedTime = (value: any) => {
    let hoursText = value.getHours();
    let minutes = value.getMinutes();
    let ampm = hoursText >= 12 ? 'PM' : 'AM';
    hoursText = hoursText % 12;
    if (hoursText) {
      hoursText = hoursText >= 10 ? hoursText: '0'+hoursText;
    } else {
      hoursText = 12;
    }
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hoursText + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  return <Modal
    open={props.show}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className="jobs-success-model change-slots-wrapper"
  >
    <div style={{ padding: "20px", background: "white", borderRadius: "7px", margin: "0 auto", width: "450px" }}>
      {props.msg === "changeInterviewSlot" ?
        <>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ fontSize: "20px", fontWeight: 900 }}>
              Change Slots
            </div>
            <CloseIcon className="pointer" onClick={props.handleClose} />
          </div>
          {data.map((slotOb: any, ind: number) => <div key={"slot-dialog-candidate " + slotOb.id}>
            <h3 key={slotOb.id} style={{ fontSize: "16px", fontWeight: 400 }} >Slot {ind + 1}</h3>
            <Grid key={slotOb.id + "a"} container direction="row" spacing={3} >
              <Grid item xs={6} sm={6} spacing={4}>
                <div className='form-group'>
                  <label style={{ fontWeight: 500 }} className="field-label" >Time</label>
                  <label onClick={() => document.getElementById(`time_${ind + 1}`)?.click()}>
                    <div style={{ display: 'flex' }} className={setSlotInputClassName(props?.slots[ind].status)}>
                    <div style={{width:'70%'}}>  {slotOb.time} <div style={{opacity:'0.6'}}> {slotOb?.time===''?'Select Time':''} </div> </div>
                     <div style={{width:'30%'}} ><img src={DropDownIcon} height={6} style={{opacity: isDisabledSlot(props?.slots[ind].status) ? 0.2: 1}} className='dropIcon' /> </div>
                    </div>
                  </label>
                </div>
                <ThemeProvider theme={defaultMaterialTheme}>
                  <TimePicker 
                 
                  className="hidden-date" disabled={isDisabledSlot(props?.slots[ind].status)} label="Time" value={new Date()} id={`time_${ind + 1}`}
                   onChange={(e: any) => {
                    const dataToSet = [...data]
                    dataToSet[ind] = { ...dataToSet[ind], time: getFormattedTime(e) }
                    setData(dataToSet)
                  }} 
                  />
                </ThemeProvider>
              </Grid>
              <Grid item xs={6} sm={6} spacing={4}>
                <div className='form-group' style={{ position: 'relative' }}>
                  <label style={{ fontWeight: 500 }} className="field-label" >Date</label>
                  <label onClick={() => document.getElementById(`date_${ind + 1}`)?.click()}>
                    <div className={setSlotInputClassName(props?.slots[ind].status)} onClick={() => document.getElementById(`date_${ind + 1}`)?.click()}>
                      {getFormattedDate(slotOb.date)} <span className='date_select'   > {getFormattedDate(slotOb.date) === '' ? 'Select Date' : ''} </span>
                      <img src={CalenderIcon} height={22} style={{ opacity: isDisabledSlot(props?.slots[ind].status) ? 0.2 : 0.5, marginLeft: 5, marginBottom: -4, float: 'right' }} alt="calender" />
                    </div>
                  </label>
                  < ThemeProvider theme={defaultMaterialTheme}>
                    <DatePicker
                      className="hidden-date"
                      style={{ position: "absolute" }}
                      openTo="year"
                      format="dd/MM/yyyy"
                      views={["year", "month", "date"]}
                      minDate={moment().add(1, "d")}
                      maxDate={moment().add(30, "d")}
                      value={moment().add(1, "d")}
                      id={`date_${ind + 1}`}
                      onChange={(e) => {
                        const dataToSet = [...data]
                        dataToSet[ind] = { ...dataToSet[ind], date: `${e?.getDate()}-${e && e?.getMonth()}-${e?.getFullYear()}` }
                        setData(dataToSet)
                      }}
                      disabled={isDisabledSlot(props?.slots[ind].status)}
                    />
                  </ ThemeProvider>
                </div>
              </Grid>
              {errorOb && errorOb[ind] && <div style={{paddingLeft:12, paddingBottom:6}} className='error-msg'>{errorOb[ind]}</div>}
            </Grid>
          </div>
          )}
          <div style={{ display: "flex", height: "56px", marginTop: "24px" }}>
            <Button onClick={confirmSlots}
              variant='outlined'
              className='hy-btn'
              style={{ fontWeight: 600, flex: 1, borderRadius: "8px" }}
            >
              CONFIRM
            </Button>
          </div>
        </>
        :
        null}
    </div>
  </Modal>
}
export function TimelineStep({ active }: { active: boolean }) {
  return <div className='timeline-step'>
    {
      active
        ?
        <div className="timeline-step-active" />
        :
        <div className="timeline-step-inactive" />
    }
  </div>
}
export function InterviewCompleted() {
  return <div style={{ display: "flex", alignItems: "center" }} >
    <img src={SelectedIcon} height={42} alt="" style={{ marginRight: "10px" }} />
    <span>
      Your interview has been successfully completed we will share your results soon.
    </span>
  </div>
}
export function InterviewCleared({ round }: { round: number }) {
  return <div style={{ display: "flex", alignItems: "center" }} >
    <img src={SelectedIcon} height={42} alt="" style={{ marginRight: "10px" }} />
    <span>
      You have cleared your round {round} interview.
    </span>
  </div>
}

export function InterviewRejected({ round }: { round: number }) {
  return <div style={{ display: "flex", alignItems: "center" }} >
    <img src={RejectedIcon} height={42} alt="" style={{ marginRight: "10px" }} />
    <span>
      Sorry ! you have not cleared your round {round} interview.
    </span>
  </div>
}

export function 
HavingIssue(params: any) {
  const [err_msg, setErrorMsg] = React.useState("");
  const Close = () => {
    params.handleClose()
    setErrorMsg("")
  }
  return (<>
    <Dialog open={params.show} fullWidth maxWidth="sm" scroll='body' className="default-timing-dialog-wrapper">
      <DialogTitle className="dialog-title">
        <div className='dialog-header' style={{ justifyContent: "space-between", display: "flex" }}>
          <div style={{ fontSize: 22 }}>Have an Issue?</div>
          <IconButton size="small" onClick={Close} data-test-id="closeIcon" ><CloseIcon className="close-icon-btn" /></IconButton>
        </div>
      </DialogTitle>
      <Formik
       data-test-id="form"
        initialValues={{
          account_id: 0,
          issue_reason: "",
          comment: ""
        }}

        onSubmit={(values) => {
          if (values.issue_reason == '' && values.comment == '') {
            return setErrorMsg("Please select any option or enter your reason")
          }
          let payload = {
            joining_issue: values.issue_reason === "audio_issue" ? 0 : values.issue_reason === "candidate_joining_issue" ? 1 : values.issue_reason === "network_issue" ? 2 : values.issue_reason === "panel_joining_issue" ? 3 : undefined,
            joining_reason: values.comment,
          }
          console.log("payload", payload, values)
          setErrorMsg("")
          params.handleSubmit(payload)
        }}
      >
        {({
          values,
          // errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          setErrors
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent className='opting-out-wrapper'>
              <div className={` border-2px  ${values.issue_reason === "audio_issue" ? 'checkdiv checked' : 'checkdiv'} `}>
                <Radio disableRipple id="issue1" name="issue_reason" checked={values.issue_reason === "audio_issue"} value="audio_issue" icon={<img src={RadioOutlinedIcon} style={{ marginBottom: 0 }} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                <label className='icon-target pointer' htmlFor="issue1">Audio Issue</label>
              </div>
              <div className={` border-2px  ${values.issue_reason === "network_issue" ? 'checkdiv checked' : 'checkdiv'} `}>
                <Radio disableRipple id="issue3" name="issue_reason" checked={values.issue_reason === "network_issue"} value="network_issue" icon={<img src={RadioOutlinedIcon} style={{ marginBottom: 0 }} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                <label className='icon-target pointer' htmlFor="issue3">Network Issue</label>
              </div>
              <div className={` border-2px  ${values.issue_reason === "panel_joining_issue" ? 'checkdiv checked' : 'checkdiv'} `}>
                <Radio disableRipple id="issue4" name="issue_reason" checked={values.issue_reason === "panel_joining_issue"} value="panel_joining_issue" icon={<img src={RadioOutlinedIcon} style={{ marginBottom: 0 }} alt="" width={24} height={24} />} checkedIcon={<img src={RadioCheckedIcon} style={{ marginBottom: 0 }} width={24} height={24} alt="" />} onChange={handleChange} />
                <label className='icon-target pointer' htmlFor="issue4">Interviewer has not joined</label>
              </div>
              <div style={{ marginTop: 30 }}>
                <h3>Other Reason</h3>
                <textarea className='reason' id="issue4" style={{ fontSize: "16px" }} name="comment" placeholder='Please Enter Your Reason' onChange={handleChange} />
              </div>
            </DialogContent>
            <p className='err_msg2'>{err_msg}</p>
            <DialogActions className='opting-out-actions'>
              <Button fullWidth style={{ borderRadius: 8, padding: "13px 0" }} variant="contained" type="submit" className="btn-3 hy-btn" >
                SUBMIT
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  </>)
}