// Customizable Area Start
import React from "react";
import { Modal, Divider } from "@material-ui/core";
import '../src/panel.web.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CatalogueController, { Props } from "./CatalogueController";
import { Calendar, momentLocalizer } from "react-big-calendar"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import 'moment/locale/en-gb';
import moment from "moment";
import { getFullName } from '../../dashboard/src/Dashboard.web';

const calenderIcon = require('../assets/calender.png')

moment.locale("en-GB");
const localizer: any = momentLocalizer(moment);

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#083247',
    },
  },
});

export const formats = {
  timeGutterFormat: 'hh A',
  dayFormat: (date: any, cultureVal: any, localizer: any) =>
    localizer.format(date, 'ddd D', cultureVal),
}

export default class Scheduled_Interviews extends CatalogueController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.setState({ ...this.state, selectedDate: new Date() });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (JSON.stringify(this.props.recruiterInterview) !== JSON.stringify(prevProps.recruiterInterview)) {
      this.setEventsData(this.props.recruiterInterview);
    }
    if (this.props.selectedJob && ((prevState.calenderViewType !== this.state.calenderViewType) ||
      (moment(prevState.selectedDate).format("DD-MM-YYYY") !== moment(this.state.selectedDate).format("DD-MM-YYYY")) ||
      (prevProps.selectedJob !== this.props.selectedJob))) {

      const cDate = this.state.selectedDate;

      if (this.state.calenderViewType === "day") {
        this.props.getInterviewRecruiter(moment(cDate).format("DD-MM-YYYY"), moment(cDate).format("DD-MM-YYYY"), this.props.selectedJob);
      } else if (this.state.calenderViewType === "week") {
        this.props.getInterviewRecruiter(moment(cDate).startOf("week").format("DD-MM-YYYY"), moment(cDate).endOf("week").format("DD-MM-YYYY"), this.props.selectedJob);
      } else if (this.state.calenderViewType === "month") {
        this.props.getInterviewRecruiter(moment(cDate).startOf("month").format("DD-MM-YYYY"), moment(cDate).endOf("month").format("DD-MM-YYYY"), this.props.selectedJob);
      }
    }
  }
   
   render() {
     return (
      <ThemeProvider theme={theme}>
      <div className="scheduled_interviews_wrapper rec-cal">
        <div className="head rec-cal">
          <div className="month rec-cal">
            <div data-test-id="calenderButton" onClick={() => { document.getElementById("recruiter-calendar-date")?.click() }}>
              <img src={calenderIcon} width={40} alt="rec-cal" style={{ cursor: 'pointer', marginLeft: 30 }} />
            </div>
            <span className="month rec-cal"> {moment(this.state.selectedDate).format("MMM-YYYY").slice(0, 3)} {moment(this.state.selectedDate).format("MMM-YYYY").slice(4, 10)} &#160;    <span className="pointer" style={{ marginLeft: 5 }} onClick={() => this.calendarPreviousEvent()} >&lt;</span> &#160;&#160;&#160; <span className="pointer" onClick={() => this.calendarNextEvent()}>&gt;</span></span>
          </div>
          <div className="calender-type rec-cal">
            <span className={this.state.calenderViewType === "day" ? "hy-active rec-cal" : ""} onClick={() => this.setState({ calenderViewType: "day" })}>Day</span>
            <span className={this.state.calenderViewType === "week" ? "hy-active rec-cal" : ""} onClick={() => this.setState({ calenderViewType: "week" })}>Week</span>
            <span className={this.state.calenderViewType === "month" ? "hy-active rec-cal" : ""} onClick={() => this.setState({ calenderViewType: "month" })}>Month</span>
          </div>
        </div>
        <Calendar
          className="calendarMain-wrapper rec-cal"
          localizer={localizer} toolbar={false}
          events={this.state.recruiterEventsFormattedData}
          formats={formats} date={this.state.selectedDate}
          view={this.state.calenderViewType} views={[this.state.calenderViewType]}
          onNavigate={(dateString: any) => this.setRecruiterMoreData(dateString)}
          components={{
            timeSlotWrapper: this.timeSlotWrapper, // Recruiter Time Slot Wrapper
            day: {event: this.eventTile}, week: { event: this.eventTile}, month: { event: this.eventTile},
          }}
          showMultiDayTimes={true} showAllEvents={false}
          onView={(view: any) => view}
        />
      </div>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker className="hidden-date rec-cal" format="dd/MM/yyyy" label="Date" value={new Date(this.state.selectedDate)} id="recruiter-calendar-date" onChange={(e: any) => { this.handleDateChange(e) }} />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
      {this.state.isOpenRecruiterSelectedEvent && <Modal
        open={this.state.isOpenRecruiterSelectedEvent}
        onClose={() => this.setState({ isOpenRecruiterSelectedEvent: false, recruiterSelectedEvent: "" })}
        className="jobs-success-model view-interview-modal-wrapper rec-cal"
      >
        <div className="infromation-block coordinator-panel-block rec-cal">
          <div className="heading rec-cal">{moment(this.state.recruiterSelectedEvent?.start).format("DD MMM yyyy")}
            <div data-test-id="closeRecruiterSelectedEvent" className="closed pointer rec-cal" onClick={() => this.setState({ isOpenRecruiterSelectedEvent: false, recruiterSelectedEvent: "" })}>X</div>
          </div>
          <div >
            {this.state.recruiterSelectedEvent !== '' &&
              <div className="interview mt-15 rec-cal">
                <label className="panel_title rec-cal">{getFullName(this.state.recruiterSelectedEvent?.title)}</label>
                <div style={{ fontSize: 13 }}>{this.formatAMPM(this.state.recruiterSelectedEvent?.actualStartTime)} - {this.formatAMPM(this.state.recruiterSelectedEvent?.actualEndTime)}</div>
                <div className="event_interviwer rec-cal" style={{ fontSize: 13, marginTop: 10 }}>Interviewer</div>
                <div className="event_title rec-cal" style={{ fontSize: 14, fontWeight: 'bold', marginTop: 0 }}>{getFullName(this.state.recruiterSelectedEvent?.panel_name)} </div>
              </div>
            }
          </div>
        </div>
      </Modal>}
      {this.state.isOpenRecruiterShowMore && 
        <Modal
          open={this.state.isOpenRecruiterShowMore}
          onClose={() => this.setState({ isOpenRecruiterShowMore: false, recruiterMoreEventData: [] })}
          className="jobs-success-model view-interview-modal-wrapper rec-cal"
        >
          <div className="infromation-block coordinator-panel-block rec-cal">
            <div className="heading rec-cal">{moment(this.state.recruiterMoreEventData[0].start).format("DD MMMM yyyy")}
              <div data-test-id="closeRecruiterShowMoreModal" className="closed pointer rec-cal" onClick={() => this.setState({ isOpenRecruiterShowMore: false, recruiterMoreEventData: [] })}>X</div>
            </div>
            {this.state.recruiterMoreEventData.map((recruiterItem: any, index: number) =>
            (
              <div key={index}>
                <div className="interview mt-15 rec-cal">
                  <label className="panel_title rec-cal">{getFullName(recruiterItem?.title)}</label>
                  <div style={{ fontSize: 13 }} className="rec-cal">{this.formatAMPM(recruiterItem.start)} - {this.formatAMPM(recruiterItem.end)}</div>
                  <div style={{ fontSize: 13, marginTop: 10 }} className="rec-cal">Interviewer</div>
                  <div className="rec-cal" style={{ fontSize: 14, fontWeight: 'bold' }}>{getFullName(recruiterItem?.panel_name)}</div>
                </div>
                {this.state.recruiterMoreEventData.length !== index+1 && <Divider className="rec-cal"/>}
              </div>
            )
            )
            }
          </div>
        </Modal>}
    </ThemeProvider>
     );
   }
 }
 
 // Customizable Area End
