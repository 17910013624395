// Customizable Area Start
import React, { useState } from 'react';
import { Theme, createStyles, withStyles, makeStyles } from '@material-ui/core/styles';
import { DefaultProfile, EditIcon } from "./assets";
import { Grid, Paper, Divider, TextField, Button, Menu, Typography, Dialog, DialogContent, DialogTitle, IconButton, 
FormControl, InputAdornment, CircularProgress} from "@material-ui/core";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import CloseIcon from '@material-ui/icons/Close';
import LandingPageController, {Props  } from "./LandingPageController";
  import { Formik } from "formik";
  import "./landing_page.css";
  import TimezoneSelect from 'react-timezone-select';
  import Autocomplete from '@material-ui/lab/Autocomplete';
  import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
  
  export const configJSON = require("../../../framework/src/config");

  export const useStylesForMyProfile = (theme:Theme) => createStyles({
    grow: {
      flexGrow: 1
    },
    root: {
      display: 'flex',
    },
  });

  const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0
    },
    formControl: {
      margin : "0px",
    },
    btn:{
        color : "#083247",
    },
    fullWidth:{
        minWidth:"100%",
        width:"100%",
    },
    itemList:{
      maxHeight:"110px ",
    }
  })
);

  export const AddPrimarySkillDialog = (props:any) => {
    const [text, setText] = useState<string>("");
    const [skillName, setSkillName] = React.useState<string[]>([ ...props.skillsData]);
    const [tempValue, setTempValue] = React.useState<string[]>([]);
    const classes = Styles();

    const handleDeleteSkill = (chipToDelete: string) => { 
      const restChips = skillName.filter((chip) => chip !== chipToDelete);
      setSkillName(restChips);
    };

    const handleChange = (event: any) => {
      if (!skillName.includes(event.target.value[0])) {
        setSkillName([...skillName, event.target.value[0]]);
        setTempValue([]);
      }
    };

    const handleOnBlur = (value: any) => {
      if (value && !skillName.includes(value)) {
        setSkillName([...skillName, value]);
        setTempValue([]);
      }
      setText("");
    }

    const handleClose = () => {
      setText("");
      setSkillName([]);
      props.handleClose();
    }

    const onSave = () => {
      const skillsData = [...skillName];
      if (text) {
        skillsData.push(text);
      }
      props.onSavePrimarySkills(skillsData);
      setText("");
      setSkillName([]);
    }

    return (
      <Dialog open={props.open} maxWidth="sm" fullWidth className='skill-dialog'>
        <DialogTitle className="dialog-title">
            Add Primary Skills
        <IconButton className="close-icon" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
        <DialogContent className="primary-skills-dialog">
          <div className="form-group"> 
            <div className="form-item">
              <FormControl
                variant="outlined"
                className={`${classes.formControl} ${classes.fullWidth}`}
              >
                <Autocomplete
                  data-test-id="autocomplete"
                  forcePopupIcon={true} fullWidth
                  popupIcon={<KeyboardArrowDownIcon style={{color: "#083247"}}/>} multiple
                  value={tempValue}
                  selectOnFocus
                  handleHomeEndKeys
                  options={props.allSkills.map((value: any) =>value.name)}
                  onChange={(e,value) => { handleChange({target:{value}}) }}
                  onBlur={(e:any) => {handleOnBlur(e.target.value)}}
                  renderOption={(option) => option}
                  freeSolo
                  getOptionDisabled={(option: any) => skillName.includes(option)}
                  renderInput={(params) => (
                    <TextField {...params} InputProps={{
                      ...params.InputProps
                    }} placeholder={!tempValue.length ? "Enter Primary Skills" : ""} onChange={()=>console.log("options",params)} variant="outlined" className="text-input multi-select-input"/>
                  )}
                />
              </FormControl>
                <Grid container className='skills-body'>
                  {(skillName as string[]).map((item,index) => (
                    <div className='skill-item' key={item+"skill"}>
                      <span className='skill-label'>{item}</span>
                      <span data-test-id="close-skill" className='close-icon-body' onClick={() => handleDeleteSkill(item)}><CloseIcon className='close-icon'/></span>
                    </div>
                  ))}
                </Grid>
            </div>
          </div>
          <div className="dialog-action">
            <Button data-test-id="save-skill-btn" fullWidth className="save-btn" onClick={onSave}>SAVE</Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  const setPanelValidation = (valueOb: any, errorsOb: any) => {
    const regexForDecimal = new RegExp(/^\d+(\.\d{1,2})?$/);
    if (!valueOb.company_name) {
      errorsOb.company_name = "Current Company Name is Required";
    }
    else if (!valueOb.experience) {
      errorsOb.experience = "Experience is Required";
    }
    else if (!regexForDecimal.test(valueOb.experience)) {
      errorsOb.experience = "Experience Format should be in decimal i.e. 0.6, 1.8, 12";
    }
    else if (!valueOb.designation) {
      errorsOb.designation = "Designation is Required";
    }
    else if (!valueOb.location) {
      errorsOb.location = "Location is Required";
    }
    else if (!valueOb.timezone || !valueOb.timezoneLabel) {
      errorsOb.timezone = "Timezone is Required";
    }
    else if (!valueOb.primary_skill.length) {
      errorsOb.primary_skill = "Primary Skill is Required";
    }
    return errorsOb;
  }

  const setValidation = (valuesOb:any, role: any) => {
    let errorsOb: any = {};
    const regexForPhoneNumber = new RegExp(/^\d{10}$/);
    const regexForAlphaNumeric = new RegExp(/^[a-z0-9]+$/i);

    if (!valuesOb.first_name) {
      errorsOb.first_name = "First Name is Required";
    } 
    else if (valuesOb.first_name && !regexForAlphaNumeric.test(valuesOb.first_name)) {
      errorsOb.first_name = "Please enter valid First Name";
    }
    else if (!valuesOb.last_name) {
      errorsOb.last_name = "Last Name is Required";
    }
    else if (valuesOb.last_name && !regexForAlphaNumeric.test(valuesOb.last_name)) {
      errorsOb.last_name = "Please enter valid Last Name";
    }
    else if (!valuesOb.phone_number) {
      errorsOb.phone_number = "Phone Number is Required";
    } 
    else if (!regexForPhoneNumber.test(valuesOb.phone_number)) {
      errorsOb.phone_number = "Phone Number should be 10 digit number";
    }
    else if(role === "panel") {
      errorsOb = setPanelValidation(valuesOb, errorsOb);
    }
    return errorsOb;
  }

  const scrollToErrorField = (allErrors:any) => {
    const errObj = Object.keys(allErrors);
    if (errObj.length) {
      const inputTextField = document.querySelector(
          `input[name=${errObj[0]}]`
      );
      const dropdownField = document.querySelector(
          `select[name=${errObj[0]}]`
      );
      if(inputTextField) {
        inputTextField.scrollIntoView({ block: 'center', behavior: 'smooth'});
      } 
      else if(dropdownField) {
        dropdownField.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }
  }
    
  const getImageUrl = (imgUrl: any) => {
    const baseURL = configJSON.baseURL;
    const url = imgUrl.charAt(0) === "/" ? baseURL+imgUrl : imgUrl;
    return url;
  }
  
  const setProfileImage = (editProfileImage: any) => {
    return editProfileImage === DefaultProfile ? DefaultProfile : getImageUrl(editProfileImage)
  }

  const getExperienceAdornment = (value: any) => {
    let exp = "";
    if (value) {
      exp = `Year${value > 1 ? "s" : ""}`
    }
    return exp;
  }

  const setFieldValidation = (value: string) => {
    return <>
      {value && <span style={{ whiteSpace: "nowrap" }} className="error-msg">{value}</span>}
    </>
  }

  const setPrimarySkillText = (item: any) => {
    return (item && item.id) ? item.id.toString() : item
  }
  const setNullToFileInput = () => {
    const fileElement = document.getElementById("profile") as any;
    if(fileElement && fileElement.value){
      fileElement.value = "";
    }
  }

export class MyProfile extends LandingPageController {
  constructor(props: Props) {
    super(props);
    this.state = {
      mobileOpen:false,
      anchorEl: null,
      mobileMoreAnchorEl:null,
      screen:"",
      defaultAppBar:true,
      name:"",
      role:"",
      show:false,
      jobsData:"",
      activeJobsData:"",
      closedJobsData:"",
      candidateSections: "",
      candidateTablePage: 0,
      joblistData:[],
      dialog:{show:false,msg:''},
      allCandidateList: "",
      searchData:'',
      manage_availability:false,
      addPanelStatus:false,
      panelListData:[],
      panelInterview:[],
      coordinatorInterview:[],
      recruiterInterview:[],
      bank_details:[],
      slot_data:[],
      profileData:{
        first_name:"",
        last_name:"",
        email:"",
        phone_number:"",
        user_profile_image:"",
        country_code:"+91",
        id:-1,
        company_name:"",
        experience:"",
        designation:"",
        location:"",
        timezone:"",
        timezoneLabel: "",
        primary_skill:[],
      },
      profileImage:{},
      JobSearchData:false,
      editProfileImage:DefaultProfile,
      profileErrors:{},
      saveProfileResponse:"",
      addManagerStatus:false,
      allManagerList: [],
      jobRoleList:[],
      isManagerEditMode: false,
      managerEditData: {},
      showDisableSlotDialog: false,
      showManageAvailabilityDialog: false,
      candidateLoading: false,
      defaultSlotTiming: [],
      availabilityData: {},
      managerLoading: false,
      error_msg:"",
      loading: false,
      openVendorDrawer: false,
      sourceList: [],
      experienceList: [],
      ManagerEditProf: false,
      slotErrorMessage:"",
      vendorList: [],
      select:false,
      vendorEditData: {},
      vendorOpeningMode: "",
      interviewSection: "",
      disable_slot:[],
      updateSlot:[],
      cordinator_change:"",
      jobsSearch: { closed:'', active:'' },
      openNotification: false,
      interviewSearchText: "",
      updatebank_detail:[],
      total_interviews:[],
      selected_job_rol:[],
      company_name:[],
      unassign_panel:[],
      assign_panel:[],
      job_data:[],
      isOpenPrimarySkillsDialog:false,
      selectedSkillIndex:-1,
      skillData: [],
      primarySkillData: [],
      unassign_data:[],
      locationList: [],
      profileKey: "",
      recruiterJobList: [],
      slots: [],
      default_slot: [],
      profileImageValidation: false,
      managerOpeningMode: ""
    };
  }
  role:any;
  enableReInitialize:boolean = true;

  handleDialogClose = () => {
    this.setState({...this.state, isOpenPrimarySkillsDialog:false})
  }
  handleOpenDialog = () => {
    this.setState({...this.state, isOpenPrimarySkillsDialog:true})
  }
  
  openSkillMenu = (target:any, index:number) => {
    this.setState({...this.state, anchorEl:target, selectedSkillIndex:index});
  }

  async componentDidMount() {
    const id = localStorage.getItem("userId");
    this.role = localStorage.getItem("role")?.toLowerCase();
    if (this.role === "panel") {
      this.getPanelProfileDetail(id);
    } else {
      this.getProfileDetail(id);
    }
    this.getAllSkills();
  }

  validateProfile = (values:any) => {
    const errorFields = setValidation(values, this.role);
    
    if(!Object.keys(errorFields).length ){
      this.setState({...this.state, profileErrors:{}, loading: true, profileImageValidation: false});
      this.saveProfileDetail(values);
    }
    else {
      this.setState({...this.state, profileErrors: {...errorFields} });
      scrollToErrorField(errorFields);
    }
  }

  saveProfileDetail = (values:any) => {
    const id = localStorage.getItem("userId");
    const profileFormData = new FormData();
    if (this.role !== "panel") {
      profileFormData.append("first_name", this.getFormattedUserName(values.first_name));
      profileFormData.append("last_name", this.getFormattedUserName(values.last_name));
      profileFormData.append("full_phone_number",`+91${values.phone_number}`);

      if (typeof values.user_profile_image === "object") {
        profileFormData.append("image",values.user_profile_image);
      }
      this.updateProfileDetail(id, profileFormData);
    } else {
      this.savePanelProfileDetails(values);
    }
  }

  savePanelProfileDetails = (values: any) => {
    const userId = localStorage.getItem("userId");
    const formDataObject = new FormData();

    const professionalDetail = [{
      company_name: values.company_name,
      experience: values.experience,
      designation: values.designation,
    }];
    
    let newSkills: any[] = [];
    let removedSkills: any[] = [];

    this.state.primarySkillData.forEach((item: any) => {
      if (!values.primary_skill.includes(item)) {
        removedSkills.push(item);
      }
    });

    newSkills = this.setNewSkills(values);
    let otherDetail: any[] = [];

    if (newSkills.length > 0) {
      newSkills.forEach((item:any) => {
        let obj:any = {};
        obj.timezone = (values.timezoneLabel && values.timezone) ? `${values.timezoneLabel}[${values.timezone}]` : "";
        obj.primary_skill = setPrimarySkillText(item);
        obj.primary_experience = "";
        obj.secondary_skill = "";
        obj.secondary_experience = "";
        otherDetail.push(obj);
      });
    } else if (values.timezoneLabel && values.timezone) {
      let obj:any = {};
      obj.timezone = `${values.timezoneLabel}[${values.timezone}]`;
      obj.primary_skill = "";
      obj.primary_experience = "";
      obj.secondary_skill = "";
      obj.secondary_experience = "";
      otherDetail.push(obj);
    }
    
    formDataObject.append("[panel[first_name]]", this.getFormattedUserName(values.first_name));
    formDataObject.append("[panel[last_name]]", this.getFormattedUserName(values.last_name));
    formDataObject.append("[panel[full_phone_number]]",`+91${values.phone_number}`);
    formDataObject.append("[panel[location]]", values.location);

    if (typeof values.user_profile_image === "object") {
      formDataObject.append("[panel[image]]",values.user_profile_image);
    }

    const profileDetail = this.state.profileData;

    if (values.company_name !== profileDetail.company_name || values.experience !== profileDetail.experience || 
      values.designation !== profileDetail.designation) {
        formDataObject.append("[panel[professional_details_attributes]]", JSON.stringify(professionalDetail));
    }
    if (otherDetail.length > 0) {
      formDataObject.append("[panel[other_details_attributes]]", JSON.stringify(otherDetail));
    }
    this.setRemovedSkills(removedSkills);
    this.setState({...this.state, profileImageValidation: false, loading: true, profileErrors: {}});
    this.updatePanelProfileDetail(userId, formDataObject);
  }

  setNewSkills = (values: any) => {
    let newSkills: any[] = [];
    values.primary_skill.forEach((item: any) => {
      if (!this.state.primarySkillData.includes(item)) {
        const skillId = this.state.skillData.find((i: any) => (i.name && i.name === item))?.id;
        newSkills.push(skillId ? skillId.toString() : item);
      }
    });
    return newSkills;
  }

  setRemovedSkills = (removedSkills: any) => {
    let removedSkillIds: any[] = [];
    if (removedSkills.length > 0) {
      removedSkills.forEach((item: any) => {
        const skillObj = this.state.skillData.find((o: any) => o.name === item);
        if (skillObj) {
          removedSkillIds.push(skillObj.id);
        }
      })
    }
    if (removedSkillIds.length > 0) {
      removedSkillIds.forEach((item: any) => {
        this.deletePanelSkill(item);
      })
    }
  }

  render() {
    const { profileData, editProfileImage, profileErrors, loading } = this.state;

      return (
        <div>
          <Formik
            initialValues={profileData}
            enableReinitialize={this.enableReInitialize}
            onSubmit={async (values) => {
              console.log(values);
            }}
          >
          {({ 
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            touched
            }) => (
             <> 
            {loading && <div className='loader-wrapper'><CircularProgress className="progress" size={70} /></div>}
            <form data-test-id="form" onSubmit={handleSubmit}> 
            <div className="my-profile-body">
              <Paper className="my-profile-paper" >
                <div className="section-header">
                  Personal Information
                </div>
                <Divider />
                <div className="personal-information-body">
                  <div className="upload-profile-image">
                    <input data-test-id="image-upload" type="file" hidden accept="image/*" onChange={(e)=>{ 
                      if (e.target.files && e.target.files[0].size < 2097152) {
                        const objectUrl = URL.createObjectURL(e.target.files[0])
                        this.setState({...this.state, editProfileImage:objectUrl, profileImageValidation: false})
                        setFieldValue('user_profile_image',e.target.files[0])
                      } else {
                        this.setState({...this.state, profileImageValidation: true, editProfileImage: DefaultProfile});
                        setFieldValue('user_profile_image', DefaultProfile);
                        setNullToFileInput();
                      }
                      }} id='profile' />
                    <label htmlFor='profile' className="pointer">
                      <img id="image" src={setProfileImage(editProfileImage)} alt="user-image" />
                      <div className="edit-image">
                        <img src={EditIcon} alt="edit-icon" />
                      </div>
                    </label>
                  {this.state.profileImageValidation && <span style={{ whiteSpace: "nowrap" }} className="error-msg">The file size should be less than 2 MB</span>}
                  </div>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <div className="form-group"> 
                        <div className="form-item">
                          <label className="form-label">First Name</label>
                            <TextField
                            data-test-id="first-name"
                            fullWidth
                            variant="outlined"
                            placeholder="Enter First Name"
                            type="text"
                            onChange={handleChange}
                            className="text-input"
                            name="first_name"
                            id="first_name"
                            //@ts-ignore
                            value={values.first_name}
                          />
                        </div>
                        {/*@ts-ignore */}
                        {setFieldValidation(profileErrors.first_name)}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group"> 
                        <div className="form-item">
                          <label className="form-label">Last Name</label>
                          <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Enter Last Name"
                            type="text"
                            onChange={handleChange}
                            className="text-input"
                            name="last_name"
                            //@ts-ignore
                            value={values.last_name}
                          />
                        </div>
                        {/*@ts-ignore */}
                        {setFieldValidation(profileErrors.last_name)}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group"> 
                        <div className="form-item">
                          <label className="form-label">Email ID</label>
                          <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Enter Email ID"
                            type="text"
                            onChange={handleChange}
                            className="text-input"
                            name="email"
                            disabled
                            //@ts-ignore
                            value={values.email}
                          />
                        </div>
                        {/*@ts-ignore */}
                        {setFieldValidation(profileErrors.email)}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group"> 
                        <div className="form-item">
                          <label className="form-label">Phone Number</label>
                          <div className="input-group" style={{display:"flex"}}>
                          <TextField 
                            className='text-input country-code-input' 
                            style={{flex:"1",minWidth:"60px"}}  
                            inputProps={{style: { textAlign: 'center' } }} 
                            variant='outlined' 
                            name="country_code" 
                            onChange={handleChange} 
                            placeholder="Code"
                            value="+91"
                          />
                          <TextField 
                            variant="outlined"
                            fullWidth
                            placeholder="Enter Phone Number"
                            onChange={handleChange}
                            className="text-input phone-input"
                            name="phone_number"
                            value={values.phone_number}
                          />
                        </div>
                        {/* @ts-ignore */}
                        {setFieldValidation(profileErrors.phone_number)}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                {this.role === "panel" &&
                <>
                <div className="section-header">
                  Professional Details
                </div>
                <Divider />
                <div className="personal-information-body p-30">
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <div className="form-group"> 
                        <div className="form-item">
                          <label className="form-label">Current Company Name</label>
                            <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Enter Current Company Name"
                            type="text"
                            onChange={handleChange}
                            className="text-input"
                            name="company_name"
                            //@ts-ignore
                            value={values.company_name}
                          />
                        </div>
                        {setFieldValidation(profileErrors.company_name)}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group"> 
                        <div className="form-item">
                          <label className="form-label">Experience</label>
                          <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Enter Experience"
                            type="text"
                            onChange={handleChange}
                            className="text-input experience"
                            name="experience"
                            //@ts-ignore
                            value={values.experience}
                            InputProps={{
                              endAdornment: <InputAdornment position="start">{getExperienceAdornment(values.experience)}</InputAdornment>,
                            }}
                          />
                        </div>
                        {setFieldValidation(profileErrors.experience)}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group"> 
                        <div className="form-item">
                          <label className="form-label">Designation</label>
                          <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Enter Designation"
                            type="text"
                            onChange={handleChange}
                            className="text-input"
                            name="designation"
                            //@ts-ignore
                            value={values.designation}
                          />
                        </div>
                        {setFieldValidation(profileErrors.designation)}
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="section-header">
                  Other Details
                </div>
                <Divider />
                <div className="personal-information-body p-30">
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <div className="form-group"> 
                        <div className="form-item">
                          <label className="form-label">Location</label>
                            <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Enter Location"
                            type="text"
                            onChange={handleChange}
                            className="text-input"
                            name="location"
                            //@ts-ignore
                            value={values.location}
                          />
                        </div>
                        {setFieldValidation(profileErrors.location)}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className='form-group'>
                        <div className="form-item">
                          <label className='form-label'>Select Timezone</label> 
                          <TimezoneSelect
                            classNamePrefix="timezone-select" 
                            placeholder="Select Timezone" 
                            className="timezone-select-container" 
                            value={values.timezone}
                            labelStyle="abbrev" 
                            onChange={(tz: any) => {
                            setFieldValue("timezone", tz.value);
                            setFieldValue("timezoneLabel", tz.label)
                        }} />
                        </div>
                        {setFieldValidation(profileErrors.timezone)}
                      </div>
                    </Grid>
                    <Grid item xs={6} className="pb-0">
                      <div className='form-group'>
                        <div className="form-item">
                          <label className='form-label'>Primary Skills</label>
                          <Grid container className={`experience-body ${!values.primary_skill.length ? "pt-8":""}`}>
                            {values.primary_skill.map((item: any,index: number) => (
                              <div className='experience-item' key={item+"p_skill"}>
                                <span className='experience-label'>
                                  {item}
                                  <MoreVertRoundedIcon className='more-icon'
                                    onClick={(e:any) => this.openSkillMenu(e.currentTarget,index)}/>
                                </span>
                              </div>
                            ))}
                            <span>
                              <Button className='add-experience-btn' onClick={this.handleOpenDialog}><AddRoundedIcon className='add-icon'/></Button>
                            </span>
                            {setFieldValidation(profileErrors.primary_skill)}
                            <Menu
                              id="simple-menu"
                              anchorEl={this.state.anchorEl}
                              keepMounted
                              open={Boolean(this.state.anchorEl)}
                              onClose={()=>this.setState({...this.state, anchorEl:null,selectedSkillIndex:-1})}
                              className="experience-remove-menu"
                              >
                              <Typography className='remove-experience-text' onClick={() => {
                                const skills = values.primary_skill;
                                if (this.state.selectedSkillIndex > -1) {
                                  skills.splice(this.state.selectedSkillIndex, 1);
                                  setFieldValue("primary_skill", skills);
                                  this.setState({...this.state,anchorEl:null,selectedSkillIndex:-1});
                                }
                              }}>Remove</Typography>
                            </Menu>
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                </>}
                <Grid container className='action-body'>
                  <Grid item md={5} xs={12}><Button data-test-id="profile-save-btn" disabled={!!this.state.saveProfileResponse} className='save-btn' variant='contained' onClick={() => this.validateProfile(values)}>SAVE PROFILE</Button></Grid>
                  {this.state.saveProfileResponse && 
                    <Grid item md={7} xs={12}>
                      {this.state.saveProfileResponse==="success" ? 
                        <div className='success-feedback'>Profile Details saved successfully</div> : 
                        <div className='input-feedback'>Something went wrong!</div>
                      }
                    </Grid>
                  }
                </Grid>
              </Paper>
            </div>
            {this.state.isOpenPrimarySkillsDialog && <AddPrimarySkillDialog 
              open={this.state.isOpenPrimarySkillsDialog} 
              handleClose={this.handleDialogClose}
              //@ts-ignore
              skillsData={values.primary_skill}
              onSavePrimarySkills={(data:any) => { 
                setFieldValue("primary_skill", data); 
                this.setState({...this.state, isOpenPrimarySkillsDialog:false})}
              }
              allSkills={this.state.skillData.filter((i: any) => (i.name && i.name !== ""))}
            />}
            </form>
            </>
            )}
          </Formik>
        </div>
      )
  }
}

export default withStyles(useStylesForMyProfile, { withTheme: true })( MyProfile )
// Customizable Area End
