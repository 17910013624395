// Customizable Area Start

import React from "react";
import { Drawer, IconButton, Grid, Button, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { EditIcon, DefaultProfile } from "./assets";

export const mainConfigJson = require("../../../framework/src/config");

export const setDisabledEmail = (openingMode: string) => {
  return openingMode === "view" || openingMode === "edit";
}
export default class AddEditManager extends React.Component<any, any> {
  managerInitialValue = {
    first_name: "",
    last_name: "",
    email: "",
    image: "",
    country_code: "+91",
    phone_number: "",
  };
  constructor(props: any) {
    super(props);
    this.state = {
      managerDetails: this.managerInitialValue,
      profileImage: DefaultProfile,
      errorObj: {},
    };
    if (this.props.managerObject && this.props.managerObject.id) {
      this.state = {
        ...this.state,
        managerDetails: { ...this.props.managerObject, country_code: `+${this.props.managerObject.country_code}` },
        profileImage: this.props.managerObject.image_url ? mainConfigJson.baseURL + this.props.managerObject.image_url : DefaultProfile,
      };
    }
  }

  handleInputChange = (e: any, name: string) => {
    const value = e.target.value;
    this.setState({
      ...this.state, managerDetails: {
        ...this.state.managerDetails,
        [name]: value
      }
    });
  }

  setHeader = (mode: string) => {
    let title = "Add New Manager";
    if (mode === "view") {
      title = "View Manager";
    } else if (mode === "edit") {
      title = "Edit Manager";
    }
    return title;
  }

  setErrors = (errorOb: any) => {
    const err = Object.keys(errorOb);
    const input = document.querySelector(`input[name=${err[0]}]`);
    if (input) {
      input.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    this.setState({ ...this.state, errorObj: errorOb });
  }

  validateManagerDetails = () => {
    const values = { ...this.state.managerDetails };
    const emailRegex = /^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const regexForPhoneNumber = new RegExp(/^\d{10}$/);
    const regexAlphabetNumber = /^(?=[a-zA-Z])[a-zA-Z0-9]*$/;
    const errorField: any = {};
    if (!values.first_name || values.first_name.trim() === "") {
      errorField.first_name = "First Name is Required";
    } else if (values.first_name && !regexAlphabetNumber.test(values.first_name)) {
      errorField.first_name = "Please enter valid First Name";
    } else if (!values.last_name || values.last_name.trim() === "") {
      errorField.last_name = "Last Name is Required";
    } else if (values.last_name && !regexAlphabetNumber.test(values.last_name)) {
      errorField.last_name = "Please enter valid Last Name";
    } else if (!values.email) {
      errorField.email = "Email is Required";
    } else if (!emailRegex.test(values.email)) {
      errorField.email = "Enter Valid Email";
    } else if (!values.phone_number) {
      errorField.phone_number = "Phone Number is Required";
    } else if (!regexForPhoneNumber.test(values.phone_number)) {
      errorField.phone_number = "Phone Number should be 10 digit number";
    }
    if (!Object.keys(errorField).length) {
      const data = { ...values };
      data.first_name = data.first_name.trim();
      data.last_name = data.last_name.trim();
      data.country_code = "91";
      this.setState({ ...this.state, errorObj: {} });
      this.props.saveManager(data, this.props.managerOpeningMode === "edit");
    } else {
      this.setErrors(errorField);
    }
  };

  render() {
    const { open, close, managerOpeningMode } = this.props;
    const { errorObj, profileImage, managerDetails } = this.state;

    const onClose = () => {
      this.setState({
        ...this.state,
        managerDetails: this.managerInitialValue,
        profileImage: DefaultProfile,
        errorObj: {},
      });
      close();
    };

    return (
      <div>
        <Drawer open={open} anchor={"right"}>
          <form>
            <div className="availability-container manager-edit-form-container">
              <div className="availability-header">
                <div data-test-id="title">{this.setHeader(managerOpeningMode)}</div>
                <IconButton onClick={onClose} size="small" data-test-id="close-manager-btn">
                  <CloseIcon className="close-icon" />
                </IconButton>
              </div>
              <div className="availability-content">
                <div
                  className="add-new-candidate-upload-image manager-profile-upload"
                  id="image"
                  style={{ top: 0, marginBottom: 25 }}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(e) => {
                      const objectUrl = URL.createObjectURL(
                        e.target.files ? e.target.files[0] : profileImage
                      );
                      this.setState({
                        ...this.state,
                        profileImage: objectUrl,
                        managerDetails: {
                          ...this.state.managerDetails,
                          image: e.target.files ? e.target.files[0] : ""
                        }
                      });
                    }}
                    id="manager-profile"
                    disabled={managerOpeningMode === "view"}
                    data-test-id="img-upload"
                  />
                  <label htmlFor="manager-profile" className={managerOpeningMode === "view" ? "" : "pointer"}>
                    <img src={profileImage} alt="user-image" />
                    <div className="add-new-candidate-edit-img add-panel-edit-msg">
                      <img src={EditIcon} alt="manager-edit-icon" />
                    </div>
                  </label>
                </div>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className="add-new-vendor-form-block manager-form-block">
                      First Name
                      <TextField
                        value={managerDetails.first_name}
                        fullWidth
                        className="text-input"
                        variant="outlined"
                        name="first_name"
                        onChange={(e) => this.handleInputChange(e, "first_name")}
                        placeholder="Enter First Name"
                        data-test-id="first_name"
                        disabled={managerOpeningMode === "view"}
                      />
                    </div>
                    {errorObj.first_name ? (
                      <span className="error-msg manager-field-error">
                        {errorObj.first_name}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <div className="add-new-vendor-form-block manager-form-block">
                      Last Name
                      <TextField
                        value={managerDetails.last_name}
                        fullWidth
                        className="text-input"
                        name="last_name"
                        variant="outlined"
                        onChange={(e) => this.handleInputChange(e, "last_name")}
                        placeholder="Enter Last Name"
                        data-test-id="last_name"
                        disabled={managerOpeningMode === "view"}
                      />
                    </div>
                    {errorObj.last_name ? (
                      <span className="error-msg manager-field-error">
                        {errorObj.last_name}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <div className="add-new-vendor-form-block manager-form-block">
                      Email ID
                      <TextField
                        value={managerDetails.email}
                        className="text-input"
                        fullWidth
                        variant="outlined"
                        name="email"
                        onChange={(e) => this.handleInputChange(e, "email")}
                        placeholder="Enter Email ID"
                        data-test-id="email"
                        disabled={setDisabledEmail(managerOpeningMode)}
                      />
                    </div>
                    {errorObj.email ? (
                      <span className="error-msg manager-field-error">{errorObj.email}</span>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <div className="add-new-vendor-form-block manager-form-block">
                      Phone Number
                      <div className="add-vendor-phone-number">
                        <TextField
                          value="+91"
                          className="text-input country-code"
                          style={{ flex: "1", minWidth: "60px" }}
                          inputProps={{ style: { textAlign: "center" } }}
                          name="country_code"
                          variant="outlined"
                          placeholder="Code"
                          disabled={managerOpeningMode === "view"}
                        />
                        <TextField
                          value={managerDetails.phone_number}
                          fullWidth
                          className="text-input phone-no"
                          name="phone_number"
                          variant="outlined"
                          placeholder="Enter Phone Number"
                          onChange={(e) => this.handleInputChange(e, "phone_number")}
                          inputProps={{ maxLength: 10 }}
                          data-test-id="phone_number"
                          disabled={managerOpeningMode === "view"}
                        />
                      </div>
                    </div>
                    {errorObj.phone_number ? (
                      <span className="error-msg manager-field-error">
                        {errorObj.phone_number}
                      </span>
                    ) : null}
                  </Grid>
                </Grid>
              </div>
              {managerOpeningMode !== "view" && <Grid container spacing={2} style={{ padding: "20px 26px" }}>
                <Grid item xs={6} className="availability-action manager-form-action">
                  <Button
                    className="cancel-btn"
                    fullWidth
                    variant="outlined"
                    data-test-id="cancel-btn"
                    onClick={onClose}
                  >
                    CANCEL
                  </Button>
                </Grid>
                <Grid item xs={6} className="availability-action manager-form-action">
                  <Button
                    className="save-btn"
                    data-test-id="save-btn"
                    fullWidth
                    onClick={this.validateManagerDetails}
                  >
                    SAVE
                  </Button>
                </Grid>
              </Grid>}
            </div>
          </form>
        </Drawer>
      </div>
    );
  }
}

// Customizable Area End
