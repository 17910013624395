// Customizable Area Start
import React, { useEffect } from "react";
import {
  Container,
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Drawer, Button, Modal, IconButton, Menu, MenuItem,
  Grid, Avatar
} from "@material-ui/core";
import MoreVert from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import StarRateIcon from '@material-ui/icons/StarRate';
import '../src/panel.web.css'
import { createTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import CheckIcon from '@material-ui/icons/Check';
import CatalogueController, { Props } from "./CatalogueController";
import Pagination from '@material-ui/lab/Pagination';
import { RejectIcon, MobileIcon, DocIcon, EmailIcon, AccountIcon, CompanyIcon, LocationIcon, DesignationIcon, TimerIcon, BrowseIcon } from "./assets";
import moment from "moment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const useStyles = makeStyles({
  root: {
    width: '100%',
    borderRadius: "8px !important",
    border: "1px solid #dedddd"
  },
  container: {
    maxHeight: "100%",
  },
});

export const mainConfigJson = require("../../../framework/src/config");

interface Column {
  id: 'candidate_name' | 'company' | 'job_role' | 'interview_slot';
  label: string;
  minWidth?: number;
  align?: 'left' | 'right';
  format?: (value: number) => string;
}

const interviewsColumn: Column[] = [
  { id: 'candidate_name', label: 'Candidates Name', minWidth: 100, align: 'left', },
  { id: 'company', label: 'Company', minWidth: 100, align: 'left', },
  {
    id: 'job_role',
    label: 'Job Role',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'interview_slot',
    label: 'Interview Slot',
    minWidth: 100,
    align: 'left',
    format: (value: number) => value.toFixed(2),
  }
]

export function PanelInterviewTable({ rows, activeTab }: { rows: any, activeTab: string }) {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [activeRows, setActiveRows] = React.useState<any[]>([]);
  const rowsPerPage = 10

  useEffect(() => {
    setPage(0)
  }, [rows]);

  useEffect(() => {
    const upcomingList = rows.upcoming.concat(rows.todays);
    let list = upcomingList;
    if (activeTab === "completed") {
      list = rows.completed;
    } else if (activeTab === "cancelled") {
      list = rows.cancelled;
    }
    setActiveRows(list);
    setPage(0);
  }, [activeTab, rows])

  const getAvatarColor = (index: number) => {
    const avatarColorArray = [
      { color: "#805cbd", backgroundColor: "#e7e0f2" },
      { color: "#f45d6b", backgroundColor: "#fdebed" },
      { color: "#56c4fb", backgroundColor: "#e0f4fe" },
      { color: "#60c596", backgroundColor: "#e5f5ee" }
    ];
    const number = index < avatarColorArray.length ? index : index % avatarColorArray.length;
    return { background: avatarColorArray[number].backgroundColor, color: avatarColorArray[number].color }
  }

  const getFormattedConfirmSlot = (date: any) => {
    let formattedSlot = "-";
    if (moment(date).isValid()) {
      const time = moment(date).format("hh:mm A");
      const newDate = moment(date).format("DD MMMM YYYY");
      formattedSlot = time + " " + newDate;
    }
    return formattedSlot;
  }  

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {interviewsColumn.map((column: any) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className="candidate-table-header-cell"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {activeRows.length === 0 ? <TableRow> <div style={{ padding: "18px 0 ", fontSize: "16px", fontWeight: 900 }}> No Data Found </div> </TableRow> : null}
            {activeRows.length > 0 ? activeRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, ind: number) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={ind}>
                  {interviewsColumn.map((column: any) => {
                    let value;
                   if (column.id === "candidate_name") {
                      value =
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <Avatar style={getAvatarColor(ind)} className={"me-2 avatar-icon"}>
                            {row.candidate_name && row.candidate_name.includes(' ') ? row.candidate_name?.split(" ")[0][0] + row.candidate_name.split(" ")[1][0] : row?.candidate_name[0]}
                          </Avatar>
                          {row.candidate_name}
                        </span>
                    } else if (column.id === "interview_slot") {
                      value = getFormattedConfirmSlot(row?.confirm_job_slot?.start_time)
                    } else {
                      value = row[column.id]
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            }): null}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: "18px", alignItems: "center" }}>
        <div> Showing {activeRows.length ? page * 10 + 1 : 0} to {page * rowsPerPage + rowsPerPage > activeRows.length ? activeRows.length : page * rowsPerPage + rowsPerPage} of {activeRows.length} entries </div>
        {
          activeRows.length > 0 ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div data-test-id="prevButton" className={page == 0 ? "next-prev-btn disable" : "next-prev-btn active"} onClick={() => { setPage(page - 1) }} >Prev</div>
              <Pagination style={{ margin: "0px 10px" }} count={Math.ceil(activeRows.length / 10)} shape="rounded" page={page + 1} onChange={(event, page) => setPage(page - 1)} hidePrevButton hideNextButton />
              <div data-test-id="nextButton" className={page + 1 < activeRows.length / 10 ? "next-prev-btn active" : "next-prev-btn disable"} onClick={page + 1 < activeRows.length / 10 ? () => { setPage(page + 1); document.querySelector('.MuiTable-stickyHeader:first-child tbody tr ')?.scrollIntoView({ behavior: 'smooth', block: 'center', }) } : () => { }} >Next</div>
            </div>
            : null
        }
      </div>
    </Paper>
  );
}

const getNewTabItemClass = (status: any) => {
  return `${status === 'new_request' ? 'active-line' : ''} tab-item`;
}

const getNewTabTitleClass = (status: any) => {
  return status === 'new_request' ? 'tab-title active' : 'tab-title'
}

const getNewTabCountClass = (status: any) => {
  return `${status === 'new_request' ? 'notif-active' : 'notif'} center inline`
}

const getApprovedTabItemClass = (status: any) => {
  return `${status === 'approve' ? 'active-line' : ''} tab-item`;
}

const getApprovedTabTitleClass = (status: any) => {
  return status === 'approve' ? 'tab-title active' : 'tab-title'
}

const getApprovedTabCountClass = (status: any) => {
  return `${status === 'approve' ? 'notif-active' : 'notif'} center inline`
}

const getUpcomingTabItemClass = (status: any) => {
  return `${status === 'upcoming' ? 'active-line' : ''} tab-item`;
}

const getUpcomingTabTitleClass = (status: any) => {
  return status === 'upcoming' ? 'tab-title active' : 'tab-title'
}

const getUpcomingTabCountClass = (status: any) => {
  return `${status === 'upcoming' ? 'notif-active' : 'notif'} center inline`
}

const getCompletedTabItemClass = (status: any) => {
  return `${status === 'completed' ? 'active-line' : ''} tab-item`;
}

const getCompletedTabTitleClass = (status: any) => {
  return status === 'completed' ? 'tab-title active' : 'tab-title'
}

const getCompletedTabCountClass = (status: any) => {
  return `${status === 'completed' ? 'notif-active' : 'notif'} center inline`
}

const getCancelledTabItemClass = (status: any) => {
  return `${status === 'cancelled' ? 'active-line' : ''} tab-item`;
}

const getCancelledTabTitleClass = (status: any) => {
  return status === 'cancelled' ? 'tab-title active' : 'tab-title'
}

const getCancelledTabCountClass = (status: any) => {
  return `${status === 'cancelled' ? 'notif-active' : 'notif'} center inline`
}

const getNameAvatarClass = (index: number) => {
  let className = "";
  if (index % 3 === 0) {
    className = 'short-name1 center inline';
  } else if (index % 3 === 1) {
    className = 'short-name2 center inline';
  } else if (index % 3 === 2) {
    className = 'short-name3 center inline';
  }
  return className;
}

const getCompanyName = (data: any) => {
  const company = data.sort((a:any, b: any) => b.id - a.id).find((i: any) => (i.company_name !== "" && i.company_name))?.company_name || "-";
  return company;
}

const getDesignation = (data: any) => {
  const designation = data.sort((a:any, b: any) => b.id - a.id).find((i: any) => (i.designation !== "" && i.designation))?.designation || "-";
  return designation;
}

const prevButtonClass = (isFirstPage: any) => {
  return isFirstPage ? "next-prev-btn disable" : "next-prev-btn active"
}

const nextButtonClass = (isActive: any) => {
  return isActive ? "next-prev-btn active" : "next-prev-btn disable"
}

const getFileName = (url: any) => {
  const filename = url.substring(url.lastIndexOf('/') + 1);
  return filename;
}

const getNumericValue = (count: any) => {
  return count ? count : 0;
}

const getLastColumnName = (status: any) => {
  return status === "new_request" ? "Requested On" : "Completed Interviews";
}

export default class Panels extends CatalogueController {
  constructor(props: Props) {
    super(props);
  }

  //@ts-ignore
  componentDidMount() {
    this.props.getNewPanelsRequests(this.state.currentPage + 1, this.state.rowsPerPage, '')
    this.props.getApprovePanelsRequests(this.state.currentPage + 1, this.state.rowsPerPage, '')
    this.getCommonSkills();
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.searchPanelKey !== this.props.searchPanelKey) {
      this.searchRequests(this.props.searchPanelKey)
    }
  }

  openPanelDetails = () => {
    this.setState({ ...this.state, openPanel: true, anchorEl: null });
    this.getPanelDetails(this.selectedPanelId);
    if (this.state.panelStatus === 'approve') {
      this.getAllInterviewList(this.selectedPanelId);
    }
  }

  getExperience = (data: any) => {
    const experience = data.sort((a:any, b: any) => b.id - a.id).find((i: any) => (i.experience !== "" && i.experience))?.experience || "-";
    let exp = "-";
    if (experience && Number(experience) > 0) {
      exp = Number(experience) <= 1 ? Math.floor(Number(experience)) + " Year" : Math.floor(Number(experience)) + " Years";
    }
    return exp;
  }

  getPanelDocumentLink = (url: any) => {
    if (url && typeof (url) === "string") {
      const newURL = url.charAt(0) === "/" ? mainConfigJson.baseURL + url : url;
      return newURL;
    }
  }

  setInterviewList = (type: any) => {
    const upcomingList = this.state.interviewList.upcoming.concat(this.state.interviewList.todays);
    let iList: any[] = [];
    if (type === "upcoming") {
      iList = upcomingList;
    } else if (type === "completed") {
      iList = this.state.interviewList.completed;
    } else if (type === "cancelled") {
      iList = this.state.interviewList.cancelled;
    }
    this.setState({...this.state, interviewListForPanel: iList});
  }

  getTimezone = (data: any) => {
    const timezoneData = data.sort((a: any, b: any) => b.id - a.id).find((ob: any) => (ob.timezone !== "" && ob.timezone))?.timezone || "";
    let timezoneString = "-";
    if (timezoneData) {
      if (timezoneData.includes("(") && timezoneData.includes(")")) {
        const charLength = timezoneData.split("").filter((o: any) => o === "(").length;
        if (charLength === 2) {
          const timeFormat = timezoneData.substr(0, timezoneData.indexOf(")") + 1);
          const timeAbbr = timezoneData.substr(timezoneData.lastIndexOf("(") + 1, (timezoneData.lastIndexOf(")") - timezoneData.lastIndexOf("(") - 1));
          timezoneString = `${timeAbbr} ${timeFormat}`;
        } else if (charLength === 1) {
          const timeFormat = timezoneData.substr(0, timezoneData.indexOf(")") + 1);
          timezoneString = `${timeFormat}`;
        }
      }
    }
    return timezoneString;
  }

  getLocation = (data: any) => {
    const timezoneData = data.sort((a: any, b: any) => b.id - a.id).find((ob: any) => (ob.timezone !== "" && ob.timezone))?.timezone || "";
    let location = "-";
    if (timezoneData) {
      if (timezoneData.includes("(") && timezoneData.includes(")")) {
        const charLength = timezoneData.split("").filter((o: any) => o === "(").length;
        if (charLength >= 1) {
          let locationText = "";
          if (timezoneData.includes(",")) {
            locationText = timezoneData.substr(timezoneData.indexOf(")") + 2, (timezoneData.indexOf(",") - timezoneData.indexOf(" ") - 1));
          } else {
            locationText = timezoneData.substr(timezoneData.indexOf(")") + 2, (timezoneData.lastIndexOf("(") - 1 - timezoneData.indexOf(")") - 2));
          }
          location = locationText;
        }
      }
    }
    return location;
  }

  getNewPanelListData = (status: any, data: any) => {
    let newListData: any[] = [];
    if (status === "new_request" && data.panel && data.panel.length > 0) {
      newListData = data.panel;
    }
    return newListData;
  }

  getApprovedPanelListData = (status: any, data: any) => {
    let approvedListData: any[] = [];
    if (status === "approve" && data.panel && data.panel.length > 0) {
      approvedListData = data.panel;
    }
    return approvedListData;
  }

  showLocationText = (obj: any) => {
    let locationValue = "-";
    if (obj.location) {
      locationValue = obj.location;
    } else if (obj.other_details && obj.other_details.length > 0) {
      locationValue = this.getLocation(obj?.other_details);
    }
    return locationValue;
  }

  showTimezoneText = (activeObj: any) => {
    let timezoneValue = "-";
    if (activeObj.other_details && activeObj.other_details.length > 0) {
      timezoneValue = this.getTimezone(activeObj?.other_details);
    }
    return timezoneValue;
  }

  showMobileNumber = (obj: any) => {
    return `${obj.country_code ? '+' + obj.country_code : ""} ${obj.phone_number}`;
  }

  showCompanyName = (obj: any) => {
    return (obj.professional_details && obj.professional_details.length > 0) ? getCompanyName(obj.professional_details) : "-";
  }

  showExperienceText = (obj: any) => {
    return (obj.professional_details && obj.professional_details.length > 0) ? this.getExperience(obj.professional_details) : "-"
  }

  showDesignationText = (obj: any) => {
    return (obj.professional_details && obj.professional_details.length > 0) ? getDesignation(obj.professional_details) : "-"
  }

  showPrimarySkills = (obj: any) => {
    let skillsArray: any[] = [];
    if (obj.primary_skill_names && obj.primary_skill_names.length > 0) {
      skillsArray =  obj.primary_skill_names;
    }
    return skillsArray;
  }

  showDocumentLink = (doc: any) => {
    return doc ? <a className="panel-id-proof" href={this.getPanelDocumentLink(doc)} target="_blank">{getFileName(doc)}</a> : "-";
  }

  render() {
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      this.setState({ ...this.state, anchorEl: event.currentTarget });
    };

    const handleClose = () => {
      this.setState({ ...this.state, anchorEl: null });
    };

    const setSelectedPanelId = (id: any) => {
      this.selectedPanelId = id;
    }



    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        {/* <Container> */}
        <Menu
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className="opt-out"
          style={{ borderRadius: 10 }}
          open={!!this.state.anchorEl}
          onClose={handleClose}
        >
          <MenuItem onClick={this.openPanelDetails}>View Details</MenuItem>
        </Menu>
        <div className="header">
          <ul>
            <li className={getNewTabItemClass(this.state.panelStatus)} onClick={() => {this.updatePanelStatus('new_request'); this.updatePageClick(0)}}>
              <div className={getNewTabTitleClass(this.state.panelStatus)}>
                New Requests
              </div>
              <div className={getNewTabCountClass(this.state.panelStatus)}><strong>{getNumericValue(this.props.newRequestData.total_panel_user)}</strong></div>
            </li>
            <li className={getApprovedTabItemClass(this.state.panelStatus)} onClick={() => {this.updatePanelStatus('approve'); this.updatePageClick(0)}}>
              <div className={getApprovedTabTitleClass(this.state.panelStatus)}>
                Approved
              </div>
              <div className={getApprovedTabCountClass(this.state.panelStatus)}><strong>{getNumericValue(this.props.approveRequestData.total_panel_user)}</strong></div>
            </li>
          </ul>
        </div>
        <TableContainer component={Paper} style={{ borderRadius: 8, marginTop: 25 }}>
          <Table stickyHeader aria-label="sticky table" style={{ padding: '0 14px' }}>
            <TableHead className="panel-table-header">
              <TableRow>
                <TableCell>Panel Name</TableCell>
                <TableCell align="left">Phone Number</TableCell>
                <TableCell align="left">Email ID</TableCell>
                <TableCell align="left">{getLastColumnName(this.state.panelStatus)}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.getNewPanelListData(this.state.panelStatus, this.props.newRequestData).map((item: any, index: number) => {
                  return (
                    <TableRow key={`new-${item.id}`} className="pointer panel-table-header-cell"
                      onClick={() => {
                        this.setState({ openPanel: !this.state.openPanel });
                        this.getPanelDetails(item.id);
                      }}>
                      <TableCell component="th" scope="row" className="block_container">
                        <div className={getNameAvatarClass(index)}>
                          <strong>
                          {item.first_name[0].toUpperCase()}{item.last_name[0].toUpperCase()}
                        </strong>
                        </div>
                        <div className="inline">
                          {item.first_name}{" "}{item.last_name}
                        </div>
                      </TableCell>
                      <TableCell data-test-id="phoneNumber" align="left">{item.phone_number}</TableCell>
                      <TableCell data-test-id="email" align="left">{item.email}</TableCell>
                      <TableCell data-test-id="panel_requested_on" align="left">{item.panel_requested_on}</TableCell>
                      <TableCell data-test-id="setSelectedPanelId" align="left"><IconButton style={{ padding: 9 }} onClick={(e: any) => { e.stopPropagation(); setSelectedPanelId(item.id); handleMenu(e); }}><MoreVert /></IconButton></TableCell>
                    </TableRow>
                  )
                })}
                {this.getApprovedPanelListData(this.state.panelStatus, this.props.approveRequestData).map((item: any, index: number) => {
                  return (
                    <TableRow data-test-id="approvedTable" key={`approved-${item.id}`} className="pointer" onClick={() => {
                      this.setState({ openPanel: !this.state.openPanel });
                      this.getPanelDetails(item.id);
                      this.getAllInterviewList(item.id);
                    }}>
                      <TableCell component="th" scope="row" className="block_container">
                        <div className={getNameAvatarClass(index)}><strong>
                          {item.first_name[0].toUpperCase()}{item.last_name[0].toUpperCase()}
                        </strong></div>
                        <div className="inline">
                          {item.first_name}{" "}{item.last_name}
                        </div>
                      </TableCell>
                      <TableCell data-test-id="phoneNumber" align="left">{item.phone_number}</TableCell>
                      <TableCell data-test-id="email" align="left">{item.email}</TableCell>
                      <TableCell data-test-id="interviewCount" align="left">{getNumericValue(item.panel_interview_count)}</TableCell>
                      <TableCell data-test-id="setSelectedPanelId" align="left"><IconButton style={{ padding: 9 }} onClick={(e: any) => { e.stopPropagation(); setSelectedPanelId(item.id); handleMenu(e); }}><MoreVert /></IconButton></TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
          {this.state.panelStatus === 'new_request' &&
            <div className="panel-table-wrapper" style={{ display: 'flex', justifyContent: 'space-between', padding: "18px", alignItems: "center" }}>
              <div> Showing {this.props.newRequestData.total_panel_user ? ((this.state.currentPage) * this.state.rowsPerPage) + 1 : 0} to {this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage > this.props.newRequestData.total_panel_user ? this.props.newRequestData.total_panel_user : this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage} of {this.props.newRequestData.total_panel_user} entries </div>
              {
                this.props.newRequestData.total_panel_user > 0 ?
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <div data-test-id="prevButton" className={prevButtonClass(this.state.currentPage == 0)} onClick={() => {this.updatePageClick(this.state.currentPage - 1)}} >Prev</div>
                    <Pagination className="panel-list-pagination" style={{ margin: "0px 10px" }} shape="rounded" count={Math.ceil(this.props.newRequestData.total_panel_user / this.state.rowsPerPage)}
                      page={this.state.currentPage + 1} onChange={(event, page) => this.updatePageClick(page - 1)}
                      hidePrevButton hideNextButton />
                    <div data-test-id="nextButton" className={nextButtonClass(this.state.currentPage + 1 <= this.props.newRequestData.total_panel_user / this.state.rowsPerPage)} onClick={this.state.currentPage + 1 <= this.props.newRequestData.total_panel_user / this.state.rowsPerPage ? () => {
                        this.updatePageClick(this.state.currentPage + 1);
                        document.querySelector('.MuiTable-stickyHeader:first-child tbody tr ')?.scrollIntoView({ behavior: 'smooth', block: 'center', })
                      } :
                        () => { }} >Next</div>
                  </div>
                  : null
              }
            </div> }
            {this.state.panelStatus === 'approve' &&
            <div className="panel-table-wrapper" style={{ display: 'flex', justifyContent: 'space-between', padding: "18px", alignItems: "center" }}>
              <div> Showing {this.props.approveRequestData.total_panel_user ? ((this.state.currentPage) * this.state.rowsPerPage) + 1 : 0} to {this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage > this.props.approveRequestData.total_panel_user ? this.props.approveRequestData.total_panel_user : this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage} of {this.props.approveRequestData.total_panel_user} entries </div>
              {
                this.props.approveRequestData.total_panel_user > 0 ?
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className={prevButtonClass(this.state.currentPage == 0)} onClick={() => {this.updatePageClick(this.state.currentPage - 1)}} >Prev</div>
                    <Pagination className="panel-list-pagination" style={{ margin: "0px 10px" }} shape="rounded" count={Math.ceil(this.props.approveRequestData.total_panel_user / this.state.rowsPerPage)}
                      page={this.state.currentPage + 1} onChange={(event, page) => this.updatePageClick(page - 1)}
                      hidePrevButton hideNextButton />

                    <div className={nextButtonClass(this.state.currentPage + 1 < this.props.approveRequestData.total_panel_user / this.state.rowsPerPage)} onClick={this.state.currentPage + 1 < this.props.approveRequestData.total_panel_user / this.state.rowsPerPage ? () => {
                        this.updatePageClick(this.state.currentPage + 1);
                        document.querySelector('.MuiTable-stickyHeader:first-child tbody tr ')?.scrollIntoView({ behavior: 'smooth', block: 'center', })
                      } :
                        () => { }} >Next</div>
                  </div>
                  : null
              }
            </div>
          }
        </TableContainer>

        <Drawer
          anchor={'right'}
          open={this.state.openPanel}
          onClose={() => this.setState({ openPanel: false, selectedPanelDocument: "", averageRating:"" })}
          PaperProps={{ style: { width: "440px" } }}
        >
          {this.state.panelStatus === 'new_request' &&
            <Container className="draw-cont">
              <div className="heading">Panel Details
                <div data-test-id="drawerClose" className="pointer" onClick={() => this.setState({ openPanel: false, selectedPanelDocument: "", averageRating:"" })}><CloseIcon /></div>
              </div>
              <div className="container2">
                <h3>Personal Details</h3>
              </div>
              <div className="container8">
                <div className="Candidate">
                  <div>
                    <img src={AccountIcon} alt="account" height={40} width={40} />
                  </div>
                  <div className="ml-14">
                    <span>Candidate Name</span>
                    <div className="info-value mtop-3">{this.state.newRequestSelected.first_name + " " + this.state.newRequestSelected.last_name}</div>
                  </div>
                </div>

                <div className="Candidate mt-20">
                  <div>
                    <img src={EmailIcon} alt="email" height={40} width={40} />
                  </div>
                  <div className="ml-14">
                    <span>Email</span>
                    <div className="info-value mtop-3">
                      {this.state.newRequestSelected.email}
                    </div>
                  </div>
                </div>

                <div className="Candidate mt-20">
                  <div>
                    <img src={MobileIcon} alt="mobile" height={40} width={40} />
                  </div>
                  <div className="ml-14">
                    <span>Mobile Number</span>
                    <div className="info-value mtop-3">
                      {this.showMobileNumber(this.state.newRequestSelected)}
                    </div>
                  </div>
                </div>

                <div className="Candidate mt-20">
                  <div>
                    <img src={DocIcon} alt="doc" height={40} width={40} />
                  </div>
                  <div className="ml-14">
                    <span>ID Proof</span>
                    <div className="info-value mtop-3" style={{ maxWidth: 300, textOverflow: "ellipsis", overflow: "hidden" }}>
                      {this.showDocumentLink(this.state.selectedPanelDocument)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="hr" />

              <div className="professional">
                <h3>Professional Details</h3>
              </div>
              <div className="Candidate color-black mt-6">
                <div className="o-color">
                  <img src={CompanyIcon} alt="company" height={23} width={22} />
                </div>
                <div className="m-10 info-value">
                  {this.showCompanyName(this.state.newRequestSelected)}
                </div>
              </div>

              <div className="Candidate color-black mt-6">
                <div className="o-color">
                  <img src={TimerIcon} alt="timer" height={22} width={22} />
                </div>
                <div className="m-10 info-value">
                  {this.showExperienceText(this.state.newRequestSelected)}
                </div>
              </div>

              <div className="Candidate color-black mt-6">
                <div className="o-color">
                  <img src={DesignationIcon} alt="designation" height={22} width={22} />
                </div>
                <div className="m-10 info-value">
                  {this.showDesignationText(this.state.newRequestSelected)}
                </div>
              </div>

              <div className="hr" />

              <div className="container-6"><h3>Other Details</h3>
                <div className="Candidate color-black mt-6">
                  <div className="o-color">
                    <img src={LocationIcon} alt="location" height={22} width={22} />
                  </div>
                  <div className="m-10 info-value">
                    {this.showLocationText(this.state.newRequestSelected)}
                  </div>
                </div>


                <div className="Candidate color-black mt-6">
                  <div className="o-color">
                    <img src={BrowseIcon} alt="browse" height={22} width={22} />
                  </div>
                  <div className="m-10 info-value">
                    {this.showTimezoneText(this.state.newRequestSelected)}
                  </div>
                </div>

              </div>
              <div className="container-6">
                <h3>Primary Skills</h3>
              </div>
              <div className="container-7">
                {this.showPrimarySkills(this.state.newRequestSelected).map((item: any, index: number) => (
                  <div className="text-6" key={`${item}-${index}`}>
                    {item}
                  </div>
                ))
                }
              </div>
              <Grid container spacing={2} className="panel-description-action" style={{ paddingTop: 20 }}>
                <Grid item xs={6}>
                  <Button fullWidth variant="outlined" className="cancel-btn" onClick={() => this.setState({ openRejectModal: true, openPanel: false, selectedPanelDocument: "", averageRating:"" })}>REJECT</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" className="save-btn" onClick={() => this.setState({ openAcceptModal: true, openPanel: false, selectedPanelDocument: "", averageRating:"" })}>ACCEPT</Button>
                </Grid>
              </Grid>


            </Container>
            } { this.state.panelStatus === 'approve' &&
              <Container className="draw-cont">
                <div className="heading">Panel Details
                  <div data-test-id="drawerClose" className="pointer" onClick={() => this.setState({ openPanel: false, selectedPanelDocument: "", averageRating:"" })}><CloseIcon /></div>
                </div><br />
                <div className="container2">
                  <div className="short-name4 center inline"><strong>JH</strong></div>
                  <div>
                    <div className="name">{this.state.approveRequestSelected.first_name + " " + this.state.approveRequestSelected.last_name}</div>
                    <div className="box">
                      <span className="star">
                        <StarRateIcon />
                      </span>
                      {getNumericValue(this.state.averageRating)} </div>
                  </div>
                </div><br />
                <div className="container3 pointer">
                  <div className="content" onClick={() => this.setState({ openInterviewList: true, openPanel: false, selectedPanelDocument: "", averageRating:"", interviewListForPanel: this.state.interviewList.completed, interviewListStatus: "completed" })}>{getNumericValue(this.state.interviewList.completed?.length)}
                    <div className="text">Completed</div>
                  </div>
                  <div className="content" onClick={() => this.setState({ openInterviewList: true, openPanel: false, selectedPanelDocument: "", averageRating:"", interviewListForPanel: this.state.interviewList.upcoming?.length, interviewListStatus: "upcoming" })}>{getNumericValue(this.state.interviewList.upcoming?.length + this.state.interviewList?.todays?.length)}
                    <div className="text1">Upcoming</div>
                  </div>
                  <div className="content" style={{ borderRight: "none" }} onClick={() => this.setState({ openInterviewList: true, openPanel: false, selectedPanelDocument: "", averageRating:"", interviewListForPanel: this.state.interviewList.cancelled, interviewListStatus: "cancelled" })}>{getNumericValue(this.state.interviewList.cancelled?.length)}
                    <div className="text2">Cancelled</div>
                  </div>
                </div>
                <div className="container-box">
                  <div className="Candidate mt-20">
                    <div>
                      <img src={EmailIcon} alt="email" height={40} width={40} />
                    </div>
                    <div className="m-10">
                      Email
                      <div className="info-value mt-6">
                        {this.state.approveRequestSelected.email}</div>
                    </div>
                  </div>

                  <div className="Candidate mt-20">
                    <div>
                      <img src={MobileIcon} alt="email" height={40} width={40} />
                    </div>
                    <div className="m-10">
                      Mobile Number
                      <div className="info-value mt-6">
                        {this.showMobileNumber(this.state.approveRequestSelected)}
                      </div>
                    </div>
                  </div>

                  <div className="Candidate mt-20">
                    <div>
                      <img src={DocIcon} alt="email" height={40} width={40} />
                    </div>
                    <div className="m-10">
                      ID Proof
                      <div className="info-value mt-6" style={{ maxWidth: 300, textOverflow: "ellipsis", overflow: "hidden" }}>
                        {this.showDocumentLink(this.state.selectedPanelDocument)}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hr" />

                <div className="professional">
                  <h3>Professional Details</h3>
                </div>
                <div className="Candidate color-black mt-6">
                  <div className="o-color">
                    <img src={CompanyIcon} alt="company" height={23} width={22} />
                  </div>
                  <div className="m-10 info-value">
                    {this.showCompanyName(this.state.approveRequestSelected)}
                  </div>
                </div>

                <div className="Candidate color-black mt-6">
                  <div className="o-color">
                    <img src={TimerIcon} alt="timer" height={22} width={22} />
                  </div>
                  <div className="m-10 info-value">
                    {this.showExperienceText(this.state.approveRequestSelected)}
                  </div>
                </div>

                <div className="Candidate color-black mt-6">
                  <div className="o-color">
                    <img src={DesignationIcon} alt="designation" height={22} width={22} />
                  </div>
                  <div className="m-10 info-value">
                    {this.showDesignationText(this.state.approveRequestSelected)}
                  </div>
                </div>

                <div className="hr" />

                <div className="container-6"><h3>Other Details</h3>
                  <div className="Candidate color-black mt-6">
                    <div className="o-color">
                      <img src={LocationIcon} alt="location" height={22} width={22} />
                    </div>
                    <div className="m-10 info-value">
                      {this.showLocationText(this.state.approveRequestSelected)}
                    </div>
                  </div>


                  <div className="Candidate color-black mt-6">
                    <div className="o-color">
                      <img src={BrowseIcon} alt="browse" height={22} width={22} />
                    </div>
                    <div className="m-10 info-value">
                      {this.showTimezoneText(this.state.approveRequestSelected)}
                    </div>
                  </div>

                </div>
                <div className="container-6">
                  <h3>Primary Skills</h3>
                </div>
                <div className="container-7">
                  {this.showPrimarySkills(this.state.approveRequestSelected).map((item: any, index: number) => (
                    <div className="text-6" key={`${item}-${index}`}>
                      {item}
                    </div>
                  ))
                  }
                </div>

              </Container>
          }
        </Drawer>

        <Drawer
          anchor={'right'}
          open={this.state.openInterviewList}
          onClose={() => this.setState({ openInterviewList: false })}
          PaperProps={{ style: { width: "77%" } }}
        >
          <div className="draw-cont" >
            <div className="heading">Interviews
              <div data-test-id="interviewClose" className="closed pointer" onClick={() => this.setState({ openInterviewList: false })}>X</div>
            </div><br />
            <div className="header">
              <ul>
                <li className={getUpcomingTabItemClass(this.state.interviewListStatus)}
                  onClick={() => { this.updateInterviewStatus('upcoming'); this.setInterviewList("upcoming") }}>
                  <div className={getUpcomingTabTitleClass(this.state.interviewListStatus)}>
                    Upcoming
                  </div>
                  <div className={getUpcomingTabCountClass(this.state.interviewListStatus)}><strong>{getNumericValue(this.state.interviewList?.upcoming.length + this.state.interviewList.todays?.length)}</strong></div>
                </li>
                <li className={getCompletedTabItemClass(this.state.interviewListStatus)}
                  onClick={() => { this.updateInterviewStatus('completed'); this.setInterviewList("completed") }}>
                  <div className={getCompletedTabTitleClass(this.state.interviewListStatus)}>
                    Completed
                  </div>
                  <div className={getCompletedTabCountClass(this.state.interviewListStatus)}><strong>{getNumericValue(this.state.interviewList.completed?.length)}</strong></div>
                </li>
                <li className={getCancelledTabItemClass(this.state.interviewListStatus)}
                  onClick={() => { this.updateInterviewStatus('cancelled'); this.setInterviewList("cancelled") }}>
                  <div className={getCancelledTabTitleClass(this.state.interviewListStatus)}>
                    Cancelled
                  </div>
                  <div className={getCancelledTabCountClass(this.state.interviewListStatus)}><strong>{getNumericValue(this.state.interviewList.cancelled?.length)}</strong></div>
                </li>
              </ul>
            </div>
            <div className="candidate-table-wrapper">
              <PanelInterviewTable activeTab={this.state.interviewListStatus} rows={this.state.interviewList} />
            </div>
          </div>
        </Drawer>
        <Modal
          open={this.state.openAcceptModal}
          onClose={this.handleConfirmationModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="jobs-success-model"
        >
          <div className="infromation-block coordinator-panel-block">
            <div className="align-msg">
              <CheckIcon style={{ color: "green" }} />
              <h4>Accept Request</h4>
              <h6>Are you sure you want to accept panel request?</h6>
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button variant="outlined" className="btn-2" onClick={() => this.handleConfirmationModal()}>
                CANCEL
              </Button>
              <Button variant="contained" className="btn-3 hy-btn" onClick={() => this.updatePanelsRequestStatus(1)}>
                CONFIRM
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.openRejectModal}
          onClose={this.handleConfirmationModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="jobs-success-model"
        >
          <div className="infromation-block coordinator-panel-block">
            <div className="align-msg">
              <img src={RejectIcon} alt='reject-icon' width="60px" />
              <h4> Reject Request</h4>
              <h6>Are you sure you want to reject panel request?</h6>
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button variant="outlined" className="btn-2" onClick={() => this.handleConfirmationModal()}>
                CANCEL
              </Button>
              <Button variant="contained" className="btn-3 hy-btn" onClick={() => this.updatePanelsRequestStatus(2)}>
                CONFIRM
              </Button>
            </div>
          </div>
        </Modal>
        {/* </Container> */}
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
}
// Customizable Area End
