import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import moment from "moment";
import { getFullName } from '../../dashboard/src/Dashboard.web';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  newRequestData?: any;
  approveRequestData?: any;
  getNewPanelsRequests?: any;
  getApprovePanelsRequests?: any;
  searchPanelKey?: any;
  panelListData?:any;
  getPanelListRequest?:any;
  getApprovePanelsList?:any;
  getPanelDisableList?:any;
  disable_slot?:any;
  getDefaultSlotTiming?:any;
  defaultSlotTiming?:any;
  panelInterview?:any;
  getInterviewPanel?:any;
  getInterviewCoordinator?:any;
  coordinatorInterview?:any;
  getDefaultManualSlotTiming?:any;
  getInterviewRecruiter?:any;
  recruiterInterview?:any;
  AddBankAccount?:any;
  bank_details?:any;
  getBankDetail?:any;
  UpdateBankDetail?:any;
  updatebank_detail?:any;
  getTotalInterview?:any;
  total_interviews?:any;
  selected_job_rol?:any;
  getInterviewList?:any;
  selectedPanel?:string;
  selectedJob?:string;
  manage_availability?:any;
  default_slot?:any;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  openPanel: boolean;
  panelStatus: string;
  newRequestSelected: any;
  approveRequestSelected: any;
  requestId: any;
  openAcceptModal: boolean;
  openRejectModal: boolean;
  openInterviewList: boolean;
  interviewListStatus: string;
  currentPage: any;
  rowsPerPage: number;
  search: string;
  calenderViewType: any;
  openEventDetailStatus: boolean;
  change_month:boolean;
  eventDetail: any;
  anchorEl: any;
  openDetailInterviewStatus: boolean;
  selectedPanelDocument: string;
  month:any;
  disable_new_data:any;
  disable_user_data:any;
  commonSkills: any;
  changeAccount:boolean;
  interviewListForPanel: any;
  isUpdatedSlot: boolean;
  blank_data:boolean;
  openFormModal:boolean;
  interviewData:boolean;
  interviewList: {
    upcoming: any,
    todays: any,
    completed: any,
    cancelled: any
  },
  holder_name:any;
  conf_account_number:any;
  account_number:any;
  ifsc_code:any;
  bank_details:any;
  error_msg:any;
  modal_data:any;
  total_reviews:any;
  update_data:boolean;
  tab_data:any;
  openDetailInterviewStatus2:boolean;
  week_modal_data:any;
  averageRating: any;
  isUpdateData:boolean;
  panel_data:boolean;
  month_select:any;
  openDetailInterviewStatus3:boolean;
  payment_image:boolean;
  disable_data:boolean;
  panel_disable_data:any;
  interview_data:any;
  slot_data:any;
  hour:string;
  minutes:any;

  //New States
  selectedDate: any;
  recruiterEvents: any;
  recruiterEventsFormattedData: any;
  recruiterSelectedEvent: any;
  isOpenRecruiterSelectedEvent: boolean;
  isOpenRecruiterShowMore: boolean;
  recruiterMoreEventData: any;
  disableSlotList: any;
  backGroundEvents: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  panelDetailsApiCallId: any;
  updateRequestsApiCallId: any;
  selectedPanelId: any;
  panelListId:any;
  commonSkillId: string = "";
  upcomingInterviewListApiId: string = "";
  todaysInterviewListApiId: string = "";
  completedInterviewListApiId: string = "";
  cancelledInterviewListApiId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      openPanel: false,
      // newRequestData: [],
      // approveRequestData: [],
      panelStatus: 'new_request',
      newRequestSelected: '',
      approveRequestSelected: '',
      requestId: '',
      openAcceptModal: false,
      openRejectModal: false,
      openInterviewList: false,
      blank_data:false,
      interviewListStatus: 'upcoming',
      currentPage: 0,
      rowsPerPage: 10,
      search: '',
      // interviewData: [],
      calenderViewType: 'week',
      openEventDetailStatus: false,
      eventDetail: {},
      anchorEl: null,
      openDetailInterviewStatus: false,
      openDetailInterviewStatus2:false,
      selectedPanelDocument: "",
      month:[],
      interviewData:false,
      changeAccount:false,
      change_month:false,
      disable_new_data:[],
      disable_user_data:[],
      panel_disable_data:[],
      interview_data:[],
      commonSkills: [],
      slot_data:[],
      interviewListForPanel: [],
      isUpdatedSlot: false,
      interviewList : {
        upcoming: [],
        completed: [],
        cancelled: [],
        todays: []
      },
      openFormModal:false,
      holder_name:"",
      conf_account_number:"",
      account_number:"",
      ifsc_code:"",
      bank_details:[],
      error_msg:{},
      modal_data:[],
      update_data:false,
      total_reviews:[],
      tab_data:[],
      week_modal_data:[],
      averageRating: "",
      isUpdateData:false,
      panel_data:false,  
      disable_data:false,
      month_select:[] ,
      payment_image:false,
      hour:"",
      minutes:"",    
      openDetailInterviewStatus3:false,
      
      //New States
      selectedDate: "",
      recruiterEvents: [],
      recruiterEventsFormattedData: [],
      recruiterSelectedEvent: "",
      isOpenRecruiterSelectedEvent: false,
      isOpenRecruiterShowMore: false,
      recruiterMoreEventData: [],
      disableSlotList: [],
      backGroundEvents: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    let today =new Date();
     this.setState({month:today})
    // this.getToken();
    // if (this.isPlatformWeb() === false) {
    //   this.props.navigation.addListener("willFocus", () => {
    //     this.getToken();
    //   });
    // }

    //this.DisableSlotData();
  }


  DisableSlotData =() => {

    this.setState({arrayHolder:[]});
    this.setState({disable_new_data:[]});
  
       this.props.disable_slot.forEach((item:any)=>{
        //  console.log('item',item.account_id)
            // this.state.arrayHolder.push({title: " ", allDay: false, start: new Date(item.slot_from), end: new Date(item.slot_to)})
        let date = new Date(item.slot_from);
         let start_date=item.slot_from.slice(8,10);
         let start_month=date.getMonth();
         let start_year=date.getFullYear();
         let start_hour=date.getUTCHours();
         let start_minute=date.getUTCMinutes();
         let new_date = new Date(item.slot_to);
   
  
   //end_date
  
  let end_date=item.slot_to.slice(8,10);
      let end_month=new_date.getMonth();
      let end_year=new_date.getFullYear();
      let end_hour=new_date.getUTCHours();
      let end_minute=new_date.getUTCMinutes();
  
    this.state.arrayHolder.push({title: "", allDay: false, 'start': new Date(start_year, start_month, start_date, start_hour, start_minute, 0),
         'end': new Date(end_year,end_month,end_date,end_hour,end_minute, 0),'panel_name':'','interview':'Interviewer'})
        })
     this.props.panelInterview?.forEach((item:any)=>{
      if(item?.confirm_job_slot?.start_time!=null){
      
        // let  start_time= moment(item.default_timing[0]?.['start_time'], ["h:mm A"]).format("HH:mm")
        // let  end_time= moment(item.default_timing[0]?.['end_time'], ["h:mm A"]).format("HH:mm")
      
        let start =item?.confirm_job_slot?.start_time;
      
      
          let date = new Date(start);
      
         //  console.log('created_at data ', date)
      
         let start_date=item?.confirm_job_slot?.start_time.slice(8,10);
          let start_month=date.getMonth();
          let start_year=date.getFullYear();
           let start_hour=date.getUTCHours();
           let start_minute=date.getUTCMinutes();
      
    //  console.log('confirm_job_slot ',start_date,start_month,start_year,start_hour,start_minute)
      
      
            let end =item?.confirm_job_slot?.end_time;
          
          let new_date = new Date(end);
      
          let end_date=item?.confirm_job_slot?.end_time.slice(8,10);
          let end_month=new_date.getMonth();
          let end_year=new_date.getFullYear();
          let end_hour=new_date.getUTCHours();
          let end_minute=new_date.getUTCMinutes();
      
      
      //  console.log('end_date',end_date,end_month,end_year,end_minute,end_hour);
        
      this.state.disable_new_data.push({title: item.candidate_name, allDay: false, 'start': new Date(start_year, start_month, start_date,start_hour,start_minute, 0),
          'end': new Date(end_year,end_month,end_date,end_hour,end_minute, 0),'panel_name':item.panel_name,'interview':'Interviewer'})
          } 
        })
     this.setState({arrayHolder:this.state.arrayHolder.concat(this.state.disable_new_data)});
  
  console.log(this.state.disable_new_data,this.state.arrayHolder.concat(this.state.disable_new_data))
  
      }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPanelList = (token: any) => {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.panelListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.panelList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getListRequest(token);
      this.getPanelList(token);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId != null && apiRequestCallId === this.panelDetailsApiCallId) {
        if (this.state.panelStatus === 'new_request') {
          this.setState({ newRequestSelected: responseJson.panel, selectedPanelDocument: responseJson.panel_document })
        } else if (this.state.panelStatus === 'approve') {
          this.setState({ approveRequestSelected: responseJson.panel, selectedPanelDocument: responseJson.panel_document, averageRating: responseJson.average_rating })
        }
      }
      if (apiRequestCallId != null && apiRequestCallId === this.updateRequestsApiCallId) {
        this.props.getNewPanelsRequests(this.state.currentPage + 1, this.state.rowsPerPage, '');
        this.props.getApprovePanelsRequests(this.state.currentPage + 1, this.state.rowsPerPage, '');
      }
      if (apiRequestCallId != null && apiRequestCallId === this.commonSkillId) {
        if (responseJson && responseJson.skill) {
          //Success Case
          this.setState({ ...this.state, commonSkills: responseJson.skill })
        } else {
          //Error Case
          console.log("error-IN_Common_SKILLS")
          this.setState({ ...this.state, commonSkills: [] })
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      if (apiRequestCallId === this.upcomingInterviewListApiId) {
        console.log(responseJson)
        if(responseJson && responseJson.candidate_details ){
          this.setState({...this.state, interviewList:  {
            ...this.state.interviewList,
            upcoming: responseJson.candidate_details
          } })
        } else {
          this.setState({...this.state, interviewList:  {
            ...this.state.interviewList,
            upcoming: []
        } })
        }
      }
      if (apiRequestCallId === this.todaysInterviewListApiId) {
        console.log(responseJson)
        if(responseJson && responseJson.candidate_details ){
          this.setState({...this.state, interviewList:  {
            ...this.state.interviewList,
            todays: responseJson.candidate_details
          } })
        } else {
          this.setState({...this.state, interviewList:  {
            ...this.state.interviewList,
            todays: []
        } })
        }
      }
      if (apiRequestCallId === this.completedInterviewListApiId) {
        console.log(responseJson)
        if(responseJson && responseJson.candidate_details ){
          this.setState({...this.state, interviewList:  {
            ...this.state.interviewList,
            completed: responseJson.candidate_details
          } })
        } else {
          this.setState({...this.state, interviewList:  {
            ...this.state.interviewList,
            completed: []
        } })
        }
      }
      if (apiRequestCallId === this.cancelledInterviewListApiId) {
        console.log(responseJson)
        if(responseJson && responseJson.candidate_details ){
          this.setState({...this.state, interviewList:  {
            ...this.state.interviewList,
            cancelled: responseJson.candidate_details
          } })
        } else {
          this.setState({...this.state, interviewList:  {
            ...this.state.interviewList,
            cancelled: []
          } })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  updatePanelStatus = async (panelStatus: any) => {
    this.setState({ panelStatus: await panelStatus, currentPage: 0 });
    // this.getPanelsRequests(this.state.token)
  }
  updateInterviewStatus = async (interviewStatus: any) => {
    this.setState({ interviewListStatus: await interviewStatus });
    // this.getPanelsRequests(this.state.token)
  }
  updatePageClick = async (page: number) => {
    this.setState({ currentPage: await page })
    if (this.state.panelStatus === 'new_request') {
      this.props.getNewPanelsRequests(this.state.currentPage + 1, this.state.rowsPerPage, this.state.search)
    } else if (this.state.panelStatus === 'approve') {
      this.props.getApprovePanelsRequests(this.state.currentPage + 1, this.state.rowsPerPage, this.state.search)
    }
  }
  searchRequests = async (searchkey: string) => {
    this.setState({ search: await searchkey })
    if (this.state.panelStatus === 'new_request') {
      this.props.getNewPanelsRequests(this.state.currentPage + 1, this.state.rowsPerPage, this.state.search)
    } else if (this.state.panelStatus === 'approve') {
      this.props.getApprovePanelsRequests(this.state.currentPage + 1, this.state.rowsPerPage, this.state.search)
    }
  }

   DayFormate2(eventDate:any){
    return moment(eventDate).format('dddd')
    }

  formatAMPM = (date: any) => {
    if (!date || typeof date.getHours !== 'function') return "00:00"
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  handleConfirmationModal = () => {
    this.setState({ openAcceptModal: false, openRejectModal: false })
  }

  getPanelDetails = (id: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.panelDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.panelRequestsApiEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updatePanelsRequestStatus = (status: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const data = {
      "status": status
    }
    this.updateRequestsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.panelApproveAcceptApiEndPoint}/${this.state.newRequestSelected.id}/status_update`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.handleConfirmationModal()
  };

  getCommonSkills = async () => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.commonSkillId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dropdownDataApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllInterviewList = (panelId: any) => {
    this.getUpcomingInterviewList(panelId);
    this.getTodaysInterviewList(panelId);
    this.getCompletedInterviewList(panelId);
    this.getCancelledInterviewList(panelId);
  }

  getUpcomingInterviewList = async (panelId: any) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upcomingInterviewListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInterviewListApiEndPoint + `upcoming&panel_id=${panelId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTodaysInterviewList = async (panelId: any) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.todaysInterviewListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInterviewListApiEndPoint + `today&panel_id=${panelId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCompletedInterviewList = async (panelId: any) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.completedInterviewListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInterviewListApiEndPoint + `completed&panel_id=${panelId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCancelledInterviewList = async (panelId: any) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      "token": localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelledInterviewListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInterviewListApiEndPoint + `cancel&panel_id=${panelId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //New Common Methods

  getDayWiseColor = (day: string) => {
    let color = "";
    switch(day) {
      case "Monday":
        color = "Purple";
        break;
      case "Tuesday":
        color = "Red";
        break;
      case "Wednesday":
        color = "skyblue";
        break;
      case "Thursday":
        color = "Green";
        break;
      case "Friday":
        color = "Yellow";
        break;
      case "Saturday":
        color = "Orange";
        break;
      case "Sunday":
        color = "Blue";
        break;
    }
    return color;
  }

  eventTile = (toolbar: any) => {
    return (
      <div>
        {toolbar.event.title && toolbar.event.title !== "available slot" &&
          <div style={{ backgroundColor: '#fff', borderRadius: 5, overflow: "hidden" }} className="bigCalendarToolbar_panel pointer" onClick={() => this.showSelectedSlotData(toolbar.event)} >
            <div style={{ display: 'flex' }}>
              { this.state.calenderViewType !== "month" &&<>
                {this.DayFormate2(toolbar.event.start) &&
                  <div style={{
                    backgroundColor: this.getDayWiseColor(this.DayFormate2(toolbar.event.start)),
                    color: this.getDayWiseColor(this.DayFormate2(toolbar.event.start))
                  }} className="calendar_line">.</div>
                }
              </>}
              <div style={{ marginLeft: 5 }}>
                <div className="event_title" style={{ fontSize: 14, fontWeight: 'bold', textTransform: 'capitalize' }}>
                  {getFullName(toolbar.event.title)}
                </div>
                <div className="event_time" style={{ fontSize: 12 }}>{this.formatAMPM(toolbar.event.start)} - {this.formatAMPM(toolbar.event.end)}</div>
                {this.state.calenderViewType !== "month" &&<>
                <div className="event_interviwer" style={{ fontSize: 12, marginTop: 4 }}>Interviewer</div>
                <div className="event_title" style={{ fontSize: 14, fontWeight: 'bold', marginTop: -4 }}>{getFullName(toolbar.event.panel_name)} </div>
                </>}
              </div>
            </div>
          </div>
        }
        {!toolbar.event.title && 
        <div className="bigCalendarToolbar2"></div>
      }
        {toolbar.event.title && toolbar.event.title === "available slot" && 
        <div className="bigCalendarToolbar3"></div>
      }
      </div>
    );
  }

  handleDateChange = (e: any) => {
    this.setState({ ...this.state, selectedDate: e })
  }

  calendarNextEvent = () => {
    const currentDate = new Date(this.state.selectedDate);
    let newDate: any = "";
    if (this.state.calenderViewType === "day") {
      newDate = currentDate.setDate(currentDate.getDate() + 1);
    } else if (this.state.calenderViewType === "week") {
      newDate = currentDate.setDate(currentDate.getDate() + 7);
    } else if (this.state.calenderViewType === "month") {
      newDate = currentDate.setMonth(currentDate.getMonth() + 1)
    }
    this.setState({ ...this.state, selectedDate: newDate });
  }

  calendarPreviousEvent = () => {
    const currentDateForPrev = new Date(this.state.selectedDate);
    let newDateForPrev: any = "";
    if (this.state.calenderViewType === "day") {
      newDateForPrev = currentDateForPrev.setDate(currentDateForPrev.getDate() - 1);
    } else if (this.state.calenderViewType === "week") {
      newDateForPrev = currentDateForPrev.setDate(currentDateForPrev.getDate() - 7);
    } else if (this.state.calenderViewType === "month") {
      newDateForPrev = currentDateForPrev.setMonth(currentDateForPrev.getMonth() - 1)
    }
    this.setState({ ...this.state, selectedDate: newDateForPrev });
  }

  showSelectedSlotData = (eventObj: any) => {
    this.setState({ ...this.state, recruiterSelectedEvent: eventObj, isOpenRecruiterSelectedEvent: true });
  }

  setRecruiterMoreData = (date: any) => {
      let filteredData = this.state.recruiterEventsFormattedData.filter((item:any) =>
         (moment(item.start).format('MM/DD/YYYY') === moment(date).format('MM/DD/YYYY') || 
         (moment(item.end).format('MM/DD/YYYY') === moment(date).format('MM/DD/YYYY')))
    );
    if (filteredData && filteredData.length > 0) {
      this.setState({...this.state, recruiterMoreEventData: filteredData, isOpenRecruiterShowMore: true});
    }
  }

  setEventsData = (interviewData: any, disableSlotData?: any) => {
    const eventsObj = interviewData;
    let disableSlotObjList = [];
    if (disableSlotData && disableSlotData.length > 0) {
      disableSlotObjList = disableSlotData;
    }
    let eList: any[] = [];
    eventsObj.forEach((item: any) => {
      let eObj: any = {};
      if (item.confirm_job_slot && item.confirm_job_slot.start_time && item.confirm_job_slot.end_time) {
        eObj = {
          start: new Date(item.confirm_job_slot.start_time), end: new Date(item.confirm_job_slot.end_time), actualStartTime: new Date(item.confirm_job_slot.start_time),
          actualEndTime: new Date(item.confirm_job_slot.end_time), title: item.candidate_name,
          job_role: item.job_role, panel_name: item.panel_name, panel_slot_id: item.panel_slot_id,
        }
        eList.push(eObj);
      }
    });
    if (this.state.calenderViewType === "month") {
      disableSlotObjList = []
    }
    this.setState({ ...this.state, recruiterEventsFormattedData: [...eList, ...disableSlotObjList] });
  }

  timeSlotWrapper = (prop: any) => {
    return (
      <div className="bigCalendarTimeSlotWrapper" style={{ height: 80 }}>
        {prop.children}
      </div>
    );
  }

  getStartAndEndDate = (date: any, view: any) => {
    const momentFormat = "DD-MM-YYYY";

    let inputStartDate = view === "day" ? moment(date).format(momentFormat) : moment(date).startOf(view).format(momentFormat);
    let inputEndDate = view === "day" ? moment(date).format(momentFormat) : moment(date).endOf(view).format(momentFormat);
    return {inputStartDate, inputEndDate};
  }
  
  // Customizable Area End
}
